<template>
  <div class="__section-main__" style="background-color: #f5f5f5">
    <div
      class="__container__ member-main-cont"
      :class="[isMobile ? 'jl-fex-column' : '']"
    >
      <div class="left-cont">
        <div class="nav-cont" style="border-bottom: 0.2rem solid #d4d9de">
          <div class="title-cont">
            <img alt="" src="@/assets/images/member-index-icon-01.png" />
            个人中心
          </div>

          <div
            v-for="(item, index) in navList1"
            :key="index"
            :class="{ active: $route.path === item.path }"
            class="nav-item"
            @click="handleToPage(item.path)"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="nav-cont">
          <div class="title-cont">
            <img alt="" src="@/assets/images/member-index-icon-01.png" />
            课程管理
          </div>

          <div
            v-for="(item, index) in navList2"
            :key="index"
            :class="{ active: $route.path === item.path }"
            class="nav-item"
            @click="handleToPage(item.path)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="right-cont">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { userExit } from "@/request/api";
import localStorage from "@/utils/localStorage";
import { mapState } from "vuex";
export default {
  name: "index",
  data() {
    return {
      navList1: [
        { name: "基本资料", path: "/member/userInfo" },
        { name: "密码修改", path: "/member/editPassword" },
        { name: "退出登录", path: "exit" },
      ],
      navList2: [{ name: "我的课程", path: "/member/course" }],

      mobileNavList: [
        { name: "基本资料", path: "/member/userInfo" },
        { name: "密码修改", path: "/member/editPassword" },
        { name: "退出登录", path: "exit" },
        { name: "我的课程", path: "/member/course" },
      ],
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  methods: {
    async handleToPage(path) {
      let that = this;
      if (path === "exit") {
        this.$confirm("确认退出登录吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            await userExit();
            localStorage.clear();
            that.$store.commit("setAccessToken", "");
            that.$store.commit("setUserInfo", null);
            that.$router.replace({
              path: "/",
            });
          })
          .catch(() => {
            console.log("取消退出");
          });
      } else {
        this.$router.push({
          path,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.member-main-cont {
  padding-top: 2rem !important;
  padding-bottom: 4rem !important;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .left-cont {
    width: 30.5rem;
    padding: 1rem;
    background-color: #ffffff;
    font-size: 2rem;
    color: #4a4948;

    .nav-cont {
      padding: 4rem 1.5rem;

      .title-cont {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 2.5rem;

        img {
          width: 2.2rem;
          height: 2.5rem;
          margin-right: 1rem;
        }
      }

      .nav-item {
        width: 100%;
        height: 5rem;
        line-height: 5rem;
        text-align: center;
        cursor: pointer;

        &:hover,
        &.active {
          background-color: var(--default-color);
          color: #ffffff;
        }
      }
    }
  }

  .right-cont {
    width: 128rem;
  }
}

@media screen and (max-width: 1023px) {
  .member-main-cont {
    .left-cont {
      width: 100%;
    }

    .right-cont {
      width: 100%;
      margin-top: 5rem;
    }
  }
}
</style>
