<template>
  <div class="__section-main__">
    <common-banner :banner-list="bannerList"></common-banner>
    <div class="__container__">
      <div class="word-cont">
        <div class="title">最新产品研发</div>
      </div>
      <div class="product-list">
        <template v-for="item in list">
          <product-item
            :key="item.id"
            :item="item"
            :width="isMobile ? '50%' : '25%'"
          ></product-item>
        </template>
      </div>
    </div>
    <div class="jl-fex-c jl-fex-item-c" style="height: 20rem">
      <el-pagination
        :current-page.sync="listQuery.page"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner";
import ProductItem from "@/components/productItem";
import { getBannerList, getProductList } from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "index",
  components: { ProductItem, CommonBanner },
  data() {
    return {
      bannerList: [],
      list: [],
      listQuery: {
        page: 1,
        limit: 12,
      },
      total: null,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  mounted() {
    this.getBannerList();
    this.getList();
  },
  methods: {
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    async getBannerList() {
      let res = await getBannerList({ cate_id: 4 });
      this.bannerList = res.data;
    },
    async getList() {
      let res = await getProductList({
        page: this.listQuery.page,
        limit: this.listQuery.limit,
      });
      this.total = res.data.total;
      this.list = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.word-cont {
  display: flex;
  align-items: flex-end;
  padding: 4rem 0;
  line-height: 1;

  .title {
    font-size: 3rem;
    color: #000000;
  }

  .desc {
    font-size: 1.6rem;
    color: #666666;
    margin-left: 3rem;
  }
}

.product-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
</style>
