import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "animate.css";
import "wowjs/css/libs/animate.css";
// Vue.use(animated)

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(ElementUI);
import "@/assets/css/common.scss";
import "@/assets/css/zlc.scss";

Vue.config.productionTip = false;

import tool from "@/utils/tool";
Vue.prototype.$tool = tool;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
