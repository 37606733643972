<template>
  <div class="project-item">
    <div
      :style="{
        backgroundImage: `url(${item.resource})`,
      }"
      class="image-cont"
    ></div>
    <div class="word-cont">
      <div class="time">{{ item.update_time }}</div>
      <div class="title">{{ item.name }}</div>
      <div class="desc">{{ item.introduction }}</div>
      <div class="read" @click="toNewsDetailPage(item.id)">查看详情></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "projectItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toNewsDetailPage(id) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.project-item {
  width: calc(33.333% - 3rem);
  margin: 1.5rem;

  .image-cont {
    width: 100%;
    padding-bottom: 100%;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem;
  }

  .word-cont {
    padding: 3rem 1rem 0 0;

    .time {
      font-size: 2rem;
      color: #000000;
    }

    .title {
      font-size: 2.8rem;
      margin-top: 2.5rem;
    }

    .desc {
      font-size: 1.6rem;
      color: #999999;
      line-height: 2;
      margin-top: 2rem;
    }

    .read {
      font-size: 1.6rem;
      color: var(--default-color);
      line-height: 2;
      margin-top: 1rem;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1023px) {
  .project-item {
    width: calc(50% - 3rem);
  }
}
</style>
