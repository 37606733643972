import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function (location) {
  return originalPush.call(this, location).catch((err) => err);
};
VueRouter.prototype.replace = function (location) {
  return originalReplace.call(this, location).catch((err) => err);
};

import HomeView from "../views/Home/index";
import FindTeacher from "@/views/Teacher/findTeacher";
import FindCourse from "@/views/Teacher/findCourse";
import News from "@/views/News/index";
import courseDetail from "@/views/Teacher/courseDetail";
import teacherDetail from "@/views/Teacher/teacherDetail";
import teacherFileDetail from "@/views/Teacher/teacherFileDetail.vue";
import newsDetail from "@/views/News/newsDetail";
import Product from "@/views/Product";
import productDetail from "@/views/Product/productDetail";
import agreementDetail from "@/views/agreement/detail.vue";

import MemberIndex from "@/views/member/index";
import MemberUserInfo from "@/views/member/user/userInfo";
import EditPassword from "@/views/member/user/editPassword";
import myCourse from "@/views/member/course";
import store from "@/store";
import myStorage from "@/utils/localStorage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/findTeacher",
    name: "findTeacher",
    component: FindTeacher,
  },
  {
    path: "/findCourse",
    name: "FindCourse",
    component: FindCourse,
  },
  {
    path: "/courseDetail",
    name: "courseDetail",
    component: courseDetail,
  },
  {
    path: "/teacherDetail",
    name: "teacherDetail",
    component: teacherDetail,
  },
  {
    path: "/teacherFileDetail",
    name: "teacherFileDetail",
    component: teacherFileDetail,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: newsDetail,
  },
  {
    path: "/product",
    name: "Product",
    component: Product,
  },
  {
    path: "/productDetail",
    name: "productDetail",
    component: productDetail,
  },
  {
    path: "/agreementDetail",
    name: "agreementDetail",
    component: agreementDetail,
  },

  {
    path: "/member",
    component: MemberIndex,
    redirect: "/member/userInfo",
    meta: { auth: true },
    children: [
      {
        path: "userInfo",
        component: MemberUserInfo,
        meta: { auth: true },
      },
      {
        path: "editPassword",
        component: EditPassword,
        meta: { auth: true },
      },
      {
        path: "course",
        component: myCourse,
        meta: { auth: true },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
});
router.beforeEach((to, from, next) => {
  if (to.meta.auth === true) {
    if (!myStorage.get("token")) {
      store.commit("setCustomLoginVisible", true);
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
