<template>
  <div class="__section-main__">
    <div class="__container__">
      <div class="course-cont" v-if="detail">
        <div class="course-header">
          <div class="name">
            {{ detail.name }}
          </div>
          <div class="icon-cont">
            <img
              @click="handleDownloadFile()"
              v-if="AccessToken && userInfo && userInfo.type === 1"
              src="@/assets/images/down-icon.png"
              alt=""
            />
          </div>
        </div>

        <div class="main-cont">
          <div class="preview-cont" v-if="isPdf">
            <iframe :src="blobUrl + '#toolbar=0'"></iframe>
            <div class="pdf-mark"></div>
          </div>
          <div class="preview-cont" v-if="isPPT || isWord || isExcel">
            <iframe
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                detail.resource
              "
            ></iframe>
            <div v-if="isPPT" class="ppt-tool-mark"></div>
            <div v-if="isWord" class="word-tool-mark"></div>
            <div v-if="isExcel" class="excel-tool-mark"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addUserCourse, getTeacherIntroductionDetail } from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "teacherFileDetail",
  data() {
    return {
      detail: null,
      isWord: false,
      isPdf: false,
      isExcel: false,
      isPPT: false,
      blobUrl: "",
    };
  },
  computed: {
    ...mapState(["AccessToken", "userInfo"]),
  },
  mounted() {
    this.getTeacherIntroductionDetail();
  },
  methods: {
    async getTeacherIntroductionDetail() {
      let res = await getTeacherIntroductionDetail({
        id: this.$route.query.id,
      });
      this.detail = res.data;
      this.previewFile(res.data.resource);
    },
    // pdf获取blob文件流
    async getBlobUrl() {
      const response = await fetch(this.detail.resource);
      const blob = await response.blob();
      this.blobUrl = window.URL.createObjectURL(blob);
    },
    previewFile(url) {
      // 根据文件格式显示预览内容
      const fileExtension = url.split(".").pop().toLowerCase();
      console.log(fileExtension);
      if (fileExtension === "docx" || fileExtension === "doc") {
        this.isWord = true;
      } else if (fileExtension === "pdf") {
        this.getBlobUrl();
        this.isPdf = true;
      } else if (fileExtension === "xls" || fileExtension === "xlsx") {
        this.isExcel = true;
      } else if (fileExtension === "ppt" || fileExtension === "pptx") {
        this.isPPT = true;
      }
    },
    // 下载文件
    async handleDownloadFile() {
      const response = await fetch(this.detail.resource);
      const blob = await response.blob();
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = this.detail.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
    },
  },
};
</script>

<style scoped lang="scss">
.preview-cont {
  width: 100%;
  height: calc(100vh - 14rem);
  position: relative;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .word-tool-mark {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 48px;
    left: 0;
    background-color: #f5f5f5;
  }

  .ppt-tool-mark {
    width: 100%;
    height: 40px;
    position: absolute;
    top: 48px;
    left: 0;
    background-color: #000000;
  }

  .excel-tool-mark {
    width: 100%;
    height: 76px;
    position: absolute;
    top: 48px;
    left: 0;
    background-color: #f5f5f5;
  }

  .pdf-mark {
    width: calc(100% - 10px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.course-cont {
  //height: 10rem;
  width: 100%;
  background-color: #e1e1e1;
  margin-top: 4.5rem;

  .course-header {
    height: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #a0a0a0;
    padding: 0 4.5rem;

    .name {
      font-size: 2rem;
      color: #ffffff;
    }

    .icon-cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 2.7rem;
        height: 2.7rem;
        cursor: pointer;
      }
    }
  }

  .main-cont {
    padding: 4rem 19rem;
  }
}
@media screen and (max-width: 1023px) {
  .course-cont {
    .main-cont {
      padding: 4rem;
    }
  }
}
</style>
