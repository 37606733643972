<template>
  <div :style="{width: `calc(${width} - 2rem)`}" class="course-item" @click="toCourseDetailPage(item.id)">
    <div :style="{backgroundImage:`url(${$tool.getImage(item.resource)})`}" class="image-cont"></div>
    <div v-if="showInfoCont" class="bottom-cont">
      <div class="title">{{ item.name }}</div>
      <div class="tag-list">
        <div class="tag-item">{{ item.course_cate_name }}</div>
        <!--        <div class="tag-item">名师讲堂</div>-->
      </div>
      <div class="user-info">
        <div :style="{backgroundImage:`url(${item.lecturer_headerimg})`}" class="avatar"></div>
        <div class="name">{{ item.lecturer_name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "courseItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: '25%'
    },
    showInfoCont: {
      type: Boolean,
      default: true,
    }
  },
  methods: {
    toCourseDetailPage(id) {
      this.$router.push({
        path: '/courseDetail',
        query:{
          id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.course-item {

  margin: 1rem;
  background: #FFFFFF;
  box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.06);
  border-radius: 0 0 5px 5px;
  cursor: pointer;

  .image-cont {
    width: 100%;
    height: 22rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bottom-cont {
    padding: 2rem 3rem;

    .title {
      font-size: 2rem;
      color: #000000;
    }

    .tag-list {
      display: flex;
      justify-content: flex-start;
      margin-top: 1.5rem;

      .tag-item {
        font-size: 1.6rem;
        color: var(--default-color);
        height: 3.5rem;
        border-radius: 2rem;
        line-height: 3.3rem;
        border: .1rem solid var(--default-color);
        padding: 0 1.5rem;
        margin-right: 1rem;
      }
    }

    .user-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;

      .avatar {
        width: 5rem;
        height: 5rem;
        background-color: #7C7C7C;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .name {
        font-size: 2rem;
        padding-left: 2rem;
      }
    }
  }
}
</style>
