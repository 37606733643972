<template>
  <div class="banner-cont">
    <div
      v-for="(item, index) in bannerList"
      :key="index"
      :class="{ active: currentBannerIndex === index }"
      :style="{ backgroundImage: `url(${item.resource})` }"
      class="banner-item"
      @click="toBannerLink(item)"
    >
      <div class="bg-cont"></div>
      <div v-if="currentBannerIndex === index && !isMobile" class="title-cont">
        <div class="title">
          <div
            v-for="(titleItem, itemIndex) in item.name.split('')"
            :key="itemIndex"
            :style="{ animationDelay: `${itemIndex / 10}s` }"
            class="animated fadeInUp"
          >
            {{ titleItem }}
          </div>
        </div>
        <div class="sub-title animated fadeInUp">{{ item.description }}</div>
      </div>
    </div>

    <div class="pagination-cont">
      <div
        v-for="(item, index) in bannerList"
        :key="index"
        :class="{ active: currentBannerIndex === index }"
        class="pagination-item"
        @click="changeCurrentBannerIndex(index)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

let changeTimer = null;
import { WOW } from "wowjs";
import { getBannerList } from "@/request/api";

export default {
  name: "indexBanner",
  props: {
    changeSecond: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      currentBannerIndex: 0,
      progressBarWidth: 0,
      bannerList: [],
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  watch: {
    currentBannerIndex: {
      immediate: true,
      handler(e) {
        clearInterval(changeTimer);
        const second = this.changeSecond * 1000;
        let reduceSecond = this.changeSecond * 1000;
        changeTimer = setInterval(() => {
          if (reduceSecond <= 0) {
            clearInterval(changeTimer);
            this.currentBannerIndex++;
            if (this.currentBannerIndex >= this.bannerList.length) {
              this.currentBannerIndex = 0;
            }
            return false;
          }
          reduceSecond -= 10;
          this.progressBarWidth = ((second - reduceSecond) / second) * 100;
        }, 10);
      },
    },
  },
  mounted() {
    this.getBannerList();
  },

  methods: {
    toBannerLink(item) {
      if (item.link) {
        window.open(item.link);
      }
    },
    async getBannerList() {
      let res = await getBannerList({ cate_id: 1 });
      this.bannerList = res.data;
      this.$nextTick(() => {
        new WOW().init();
      });
    },
    changeCurrentBannerIndex(index) {
      clearInterval(changeTimer);
      this.currentBannerIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes moveTopBottom {
  0% {
    transform: translateY(0rem);
  }
  50% {
    transform: translateY(-1rem);
  }
  100% {
    transform: translateY(0);
  }
}

.banner-cont {
  width: 100%;
  height: 100vh;
  position: relative;

  .banner-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: all 1s;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.active {
      z-index: 10;
      opacity: 1;
    }

    .bg-cont {
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 1s;
    }

    .title-cont {
      color: #ffffff;
      position: absolute;
      left: 15rem;
      top: 40rem;
      z-index: 100;

      .title {
        line-height: 1;
        margin-top: 1rem;
        display: flex;
        //font-family: "SourceHanSansCN-Bold";

        & > div {
          font-size: 10rem;
          font-weight: 600;
          text-shadow: 0 0 4rem rgba(0, 0, 0, 1);
        }
      }

      .sub-title {
        font-size: 3rem;
        margin-top: 5rem;
      }
    }
  }

  .pagination-cont {
    position: absolute;
    bottom: 3rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50;

    .pagination-item {
      width: 9rem;
      height: 1rem;
      background-color: rgba(255, 255, 255, 0.6);
      margin: 0 1rem;
      transition: all 0.5s;

      &.active {
        background-color: #ffffff;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .banner-cont {
    width: 100%;
    height: 25vh;
  }
}
</style>
