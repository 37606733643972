import Axios from "axios";
import myStorage from "@/utils/localStorage";
import sessionStorage from "@/utils/sessStorage";
import {BASE_URL} from "@/config/config";
import {Loading, Message } from "element-ui";
import store from "@/store";
import router from "@/router";
// import router from "@/router";
// create an axios instance
const axios = Axios.create({
    baseURL: BASE_URL, // url = base url + request url
    // withCredentials: false, // send cookies when cross-domain requests
    // timeout: 10000, // request timeout
});
// request interceptor
axios.interceptors.request.use(
    (config) => {
        config.headers["token"] = myStorage.get('token') || "";
        // config.headers["Application"] = myStorage.get('Application') || "";
        return config;
    },
    (error) => {
        // do something with request error
        // console.log(error); // for debug
        return Promise.reject(error);
    }
);
// response interceptor
axios.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    (response) => {
        let res = response.data;
        if (res.code.toString() !== '200') {
            Loading.service().close();
            // if(res.message === 'token失效,请重新登录!'){
            //     // Message.error("token失效,请重新登录!");
            //     // store.commit('SET_USERINFO',null);
            //     // store.commit("SET_LOGINREQUIRED",true);
            //     router.replace({path:'/login'})
            //     myStorage.clear();
            //     return false;
            // }
            Message.error(res.msg);
            return Promise.reject(response)
        } else {
            return response.data
        }
    },
    (response) => {
        Loading.service().close();
        Message.error('网络错误');
        return Promise.reject(response)
    }
);
export default axios;
