<template>
  <div>
    <div
      v-if="showHeader"
      :style="{ height: `${$store.state.isMobile ? '12rem' : '12rem'}` }"
      style="width: 100%"
    ></div>
    <div class="custom-header">
      <div
        :class="{ active: scrollTop >= 10 || showHeader }"
        class="header-cont"
      >
        <div class="jl-fex-l jl-fex-item-c">
          <div class="image-cont">
            <img
              :src="
                scrollTop >= 10 || showHeader
                  ? require('@/assets/images/logo-orange.png')
                  : require('@/assets/images/logo-white.png')
              "
              alt=""
            />
          </div>
          <div class="nav-list">
            <template v-for="(item, index) in navList">
              <template v-if="item.children && item.children.length > 0">
                <div
                  :key="index"
                  :class="{
                    active: item.children
                      .map((child) => child.path)
                      .includes($route.path),
                  }"
                  class="nav-item"
                >
                  {{ item.name }}
                  <div class="nav-sub-list">
                    <router-link
                      v-for="(subItem, subIndex) in item.children"
                      :key="subIndex"
                      :class="{ active: $route.path === subItem.path }"
                      :to="subItem.path"
                      class="nav-sub-item"
                      tag="div"
                    >
                      {{ subItem.name }}
                    </router-link>
                  </div>
                </div>
              </template>
              <template v-else>
                <router-link
                  :key="index"
                  :class="{ active: $route.path === item.path }"
                  :to="item.path"
                  class="nav-item"
                  tag="div"
                >
                  {{ item.name }}
                </router-link>
              </template>
            </template>
          </div>
        </div>
        <div
          v-if="!isMobile"
          :class="{ active: scrollTop >= 10 || showHeader }"
          class="search-cont"
        >
          <div class="screen-cont">
            <div class="title-cont" @click="showScreenCont = true">
              <div>
                {{
                  searchList.find((item) => item.id === currentSearchId).name
                }}
              </div>
              <img
                v-if="scrollTop >= 10 || showHeader"
                alt=""
                src="@/assets/images/down-icon-dark.png"
              />
              <img v-else alt="" src="@/assets/images/down-arrow.png" />
            </div>
            <ul v-if="showScreenCont">
              <li
                v-for="item in searchList"
                :key="item.id"
                :class="{ active: item.id === currentSearchId }"
                @click="(currentSearchId = item.id)((showScreenCont = false))"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <input
            v-model="keyword"
            class="search-input"
            placeholder="请输入关键字搜索"
            type="text"
            @keydown.enter="handleSearch()"
          />
        </div>
        <div
          v-if="!AccessToken"
          class="login-btn"
          @click="handleOpenCustomLogin()"
        >
          立即登录
        </div>
        <div v-else class="user-cont" @click="toMemberIndexPage()">
          <div
            :style="{
              backgroundImage: `url(${
                userInfo.headerimg && userInfo.headerimg.url
              })`,
            }"
            class="avatar"
          ></div>
          <div class="name">{{ userInfo.nickname }}</div>
        </div>
      </div>
    </div>

    <custom-login></custom-login>
  </div>
</template>

<script>
import CustomLogin from "@/components/customLogin";
import { mapState } from "vuex";

export default {
  name: "customHeader",
  components: { CustomLogin },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      navList: [
        { name: "首页", path: "/" },
        {
          name: "师资中心",
          path: "",
          children: [
            { name: "找讲师", path: "/findTeacher" },
            { name: "找课程", path: "/findCourse" },
          ],
        },
        { name: "项目播报", path: "/news" },
        { name: "产品研发", path: "/product" },
      ],
      scrollTop: 0,
      searchList: [
        { name: "找课程", id: 1 },
        { name: "找讲师", id: 2 },
      ],
      currentSearchId: 1,
      showScreenCont: false,
      keyword: "",
    };
  },
  computed: {
    ...mapState(["AccessToken", "userInfo", "isMobile"]),
  },
  mounted() {
    this.listenToScrollTop();
  },
  methods: {
    handleSearch() {
      let path = "";
      if (this.currentSearchId === 1) {
        path = "/findCourse";
      } else if (this.currentSearchId === 2) {
        path = "/findTeacher";
      }
      this.$router.replace({
        path,
        query: {
          keyword: this.keyword,
        },
      });
      this.keyword = "";
    },
    handleOpenCustomLogin() {
      this.$store.commit("setCustomLoginVisible", true);
    },
    toMemberIndexPage() {
      this.$router.push({
        path: "/member",
      });
    },
    listenToScrollTop() {
      this.scrollTop = document.documentElement.scrollTop;
      let that = this;
      window.addEventListener("scroll", function (e) {
        that.scrollTop = document.documentElement.scrollTop;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search-cont {
  width: 45rem;
  height: 6rem;
  border: 0.1rem solid #ffffff;
  border-radius: 3rem;
  position: relative;
  display: flex;

  .screen-cont {
    width: 13rem;
    height: 100%;
    border-right: 0.1rem solid #ffffff;
    color: #ffffff;
    padding: 0 1rem;
    position: relative;
    cursor: pointer;

    .title-cont {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        flex: 1;
        text-align: center;
        font-size: 1.6rem;
      }

      img {
        width: 2rem;
      }
    }

    ul {
      width: 13rem;
      position: absolute;
      top: 5.9rem;
      left: 0;
      background-color: #ffffff;

      li {
        width: 100%;
        height: 6rem;
        line-height: 6rem;
        text-align: center;
        font-size: 1.6rem;
        color: #000000;

        &.active {
          background-color: var(--default-color);
          color: #ffffff;
        }
      }
    }
  }

  .search-input {
    flex: 1;
    padding: 0 1.5rem;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 1.6rem;
    color: #ffffff;
  }

  .search-input::placeholder {
    color: #f2f2f2;
  }

  &.active {
    border: 0.1rem solid #000000;

    .screen-cont {
      color: #000000;
      border-color: #000000;
    }

    .search-input {
      color: #000000;
    }

    .search-input::placeholder {
      color: #999999;
    }
  }
}

.custom-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  width: 100%;
  height: 12rem;

  .header-cont {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14.5rem;
    transition: all 0.5s;

    &.active {
      background-color: #ffffff;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

      .nav-list {
        .nav-item {
          color: #333333;
        }
      }
    }

    .image-cont {
      //width: 18.9rem;
      //height: 6.2rem;
      width: 16.4rem;
      height: 7.9rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .nav-list {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7rem;

      .nav-item {
        font-size: 2rem;
        color: #ffffff;
        line-height: 11.8rem;
        margin: 0 1.6rem;
        cursor: pointer;
        transition: all 0.5s;
        position: relative;

        &.active,
        &:hover {
          color: var(--default-color);
        }

        &:hover {
          .nav-sub-list {
            //display: block;
            max-height: 1000rem;
          }
        }
      }

      .nav-sub-list {
        //display: none;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        top: 8rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ffffff;
        color: #666666;
        border-radius: 1rem;
        line-height: 5rem;
        width: 15rem;
        box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.06);
        transition: all 0.3s;

        .nav-sub-item {
          text-align: center;

          &.active,
          &:hover {
            color: var(--default-color);
          }
        }
      }
    }

    .login-btn {
      width: 13rem;
      height: 4rem;
      transition: all 0.5s;
      font-size: 2rem;
      background-color: var(--default-color);
      color: #ffffff;
      border-radius: 0.5rem;
      text-align: center;
      line-height: 4rem;
      cursor: pointer;
    }

    .user-cont {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      .avatar {
        width: 7rem;
        height: 7rem;
        border-radius: 100%;
        background-color: #f7f7f7;
        margin-right: 2rem;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      .name {
        font-size: 2rem;
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .custom-header {
    .header-cont {
      padding: 0 5rem 0 5rem;
    }
  }
}
</style>
