<template>
  <div v-if="customLoginVisible" class="custom-login">
    <div class="login-bg" @click="handleCloseCustomLogin()"></div>
    <div class="login-cont">
      <div class="jl-fex-c" style="margin-bottom: 5rem">
        <div class="login-title">{{ popupTitle }}</div>
      </div>
      <template v-if="popupType === 'login'">
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-01.png" />
          <input
            v-model="loginData.mobile"
            placeholder="请输入手机号"
            type="number"
          />
        </div>
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-03.png" />
          <input
            v-model="loginData.password"
            placeholder="请输入密码"
            type="password"
          />
        </div>
        <div class="jl-fex-r">
          <div class="forgot-password" @click="popupType = 'forgot'">
            忘记密码
          </div>
        </div>
        <div class="submit-btn" @click="handleLogin()">登录</div>
        <div class="jl-fex-c">
          <div class="to-register" @click="popupType = 'register'">
            没有账号去注册
          </div>
        </div>
      </template>

      <template v-if="popupType === 'register'">
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-02.png" />
          <input
            v-model="registerData.name"
            placeholder="请输入姓名"
            type="text"
          />
        </div>
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-01.png" />
          <input
            v-model="registerData.mobile"
            placeholder="请输入手机号"
            type="number"
          />
        </div>
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-03.png" />
          <input
            v-model="registerData.password"
            placeholder="请输入密码"
            type="password"
          />
        </div>
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-04.png" />
          <input
            v-model="code"
            placeholder="短信验证码"
            style="width: 30rem"
            type="number"
          />

          <div
            :class="{ disabled: disabled }"
            class="code-btn"
            @click="getVerificationCode()"
          >
            {{ disabled ? countDown + "秒后获取" : "获取验证码" }}
          </div>
        </div>
        <!--        <div class="form-item">-->
        <!--          <img alt="" src="@/assets/images/login-icon-05.png" />-->
        <!--          <div class="screen-cont" @click="showInstitutionList()">-->
        <!--            <div class="name">-->
        <!--              {{-->
        <!--                registerData.organization_id-->
        <!--                  ? organizationList.find(-->
        <!--                      (item) => item.id === registerData.organization_id-->
        <!--                    ).name-->
        <!--                  : "请选择机构"-->
        <!--              }}-->
        <!--            </div>-->
        <!--            <img alt="" src="@/assets/images/login-icon-06.png" />-->
        <!--            <ul v-if="organizationListVisible" class="institution-list">-->
        <!--              <li-->
        <!--                v-for="item in organizationList"-->
        <!--                :key="item.id"-->
        <!--                :class="{ active: registerData.organization_id === item.id }"-->
        <!--                @click.stop.prevent="chooseOrganization(item.id)"-->
        <!--              >-->
        <!--                {{ item.name }}-->
        <!--              </li>-->
        <!--            </ul>-->
        <!--          </div>-->
        <!--        </div>-->
        <div
          class="submit-btn"
          style="margin-top: 3rem"
          @click="handleRegister()"
        >
          注册
        </div>
        <div class="jl-fex-c">
          <div class="to-register" @click="popupType = 'login'">去登录</div>
        </div>
      </template>

      <template v-if="popupType === 'forgot'">
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-01.png" />
          <input
            v-model="forgotData.mobile"
            placeholder="请输入手机号"
            type="number"
          />
        </div>
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-04.png" />
          <input
            v-model="code"
            placeholder="短信验证码"
            style="width: 30rem"
            type="number"
          />
          <div
            :class="{ disabled: disabled }"
            class="code-btn"
            @click="getVerificationCode()"
          >
            {{ disabled ? countDown + "秒后获取" : "获取验证码" }}
          </div>
        </div>
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-03.png" />

          <input
            v-model="forgotData.password"
            placeholder="输入新密码"
            type="password"
          />
        </div>
        <div class="form-item">
          <img alt="" src="@/assets/images/login-icon-03.png" />
          <input
            v-model="forgotData.re_password"
            placeholder="确认密码"
            type="password"
          />
        </div>
        <div
          class="submit-btn"
          style="margin-top: 3rem"
          @click="handleForgot()"
        >
          确定
        </div>
        <div class="jl-fex-c">
          <div class="to-register" @click="popupType = 'login'">去登录</div>
        </div>
      </template>

      <div class="agreement-cont">
        <img
          v-if="!checkAgreement"
          alt=""
          src="@/assets/images/checkbox-icon.png"
          @click="checkAgreement = true"
        />
        <img
          v-if="checkAgreement"
          alt=""
          src="@/assets/images/checkbox-icon-checked.png"
          @click="checkAgreement = false"
        />
        <span>我已阅读并同意</span>
        <span class="orange pointer" @click="toAgreementDetailPage(1)"
          >《服务协议》</span
        >
        <span class="orange">、</span>
        <span class="orange pointer" @click="toAgreementDetailPage(2)"
          >《隐私策略》</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  userLogin,
  userRegister,
  checkVerificationCode,
  getVerificationCode,
  forgotPwd,
  getOrganizationList,
  getUserInfo,
} from "@/request/api";
import localStorage from "@/utils/localStorage";

export default {
  name: "customLogin",
  data() {
    return {
      popupType: "login", // login=>登录   register=>注册   forgot=>忘记密码
      checkAgreement: true,
      loginData: {
        mobile: "",
        password: "",
      },
      registerData: {
        mobile: "",
        password: "",
        organization_id: "",
        name: "",
      },
      forgotData: {
        mobile: "",
        password: "",
        re_password: "",
      },
      code: "",
      organizationListVisible: false,
      organizationList: [],

      timer: null,
      countDown: 60,
      lock: true,
      disabled: false,
    };
  },
  computed: {
    ...mapState(["customLoginVisible"]),
    popupTitle() {
      let title = "账号登录";
      if (this.popupType === "login") {
        title = "账号登录";
      } else if (this.popupType === "register") {
        title = "注册账号";
      } else if (this.popupType === "forgot") {
        title = "忘记密码";
      }
      return title;
    },
  },
  methods: {
    toAgreementDetailPage(id) {
      let routeData = this.$router.resolve({
        path: "/agreementDetail",
        query: { id },
      });
      window.open(routeData.href, "_blank");
    },
    //获取用户信息
    async getUserInfo() {
      let res = await getUserInfo();
      localStorage.set("userInfo", res.data);
      this.$store.commit("setUserInfo", res.data);
    },
    //验证忘记密码数据
    checkForgotData() {
      if (!this.$tool.verifyPhone(this.forgotData.mobile)) {
        this.$message.warning("请输入正确的手机号码");
        return false;
      } else if (!this.code) {
        this.$message.warning("请输入验证码");
        return false;
      } else if (!this.forgotData.password) {
        this.$message.warning("请输入密码");
        return false;
      } else if (this.forgotData.password !== this.forgotData.re_password) {
        this.$message.warning("两次密码不一致");
        return false;
      }
      return true;
    },
    // 忘记密码提交
    async handleForgot() {
      if (this.checkForgotData()) {
        await checkVerificationCode({
          mobile: this.forgotData.mobile,
          type: 2,
          code: this.code,
        });
        let loading = this.$loading({ background: "rgba(0,0,0,0.5)" });
        await forgotPwd({
          mobile: this.forgotData.mobile,
          password: this.forgotData.password,
        });
        loading.close();
        this.$message.success("修改成功");
        this.popupType = "login";
        this.forgotData = {
          mobile: "",
          password: "",
          re_password: "",
        };
        this.code = "";
      }
    },

    // 发送验证码
    async getVerificationCode() {
      let mobile = "";
      let type = "";
      if (this.popupType === "register") {
        mobile = this.registerData.mobile;
        type = 1;
      } else if (this.popupType === "forgot") {
        mobile = this.forgotData.mobile;
        type = 2;
      }
      if (!this.$tool.verifyPhone(mobile)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      }
      if (!this.lock) return;
      if (this.disabled) return;
      this.lock = false;
      setTimeout(() => {
        this.lock = true;
      }, 2000);
      let loading = this.$loading({ background: "rgba(0,0,0,0.5)" });
      await getVerificationCode({ mobile, type });
      loading.close();
      this.$message.success("发送成功");
      clearInterval(this.timer);
      this.disabled = true;
      this.timer = setInterval(() => {
        if (this.countDown <= 1) {
          clearInterval(this.timer);
          this.disabled = false;
          this.countDown = 5;
          return false;
        }
        this.countDown--;
      }, 1000);
    },
    // 选择机构
    chooseOrganization(id) {
      this.registerData.organization_id = id;
      this.organizationListVisible = false;
    },
    // 显示隐藏机构列表，获取机构列表
    async showInstitutionList() {
      if (this.organizationListVisible) {
        this.organizationListVisible = false;
      } else {
        let res = await getOrganizationList();
        this.organizationList = res.data;
        this.organizationListVisible = true;
      }
    },
    // 验证注册数据
    checkRegisterData() {
      if (!this.registerData.name) {
        this.$message.warning("请输入姓名");
        return false;
      } else if (!this.$tool.verifyPhone(this.registerData.mobile)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      } else if (!this.registerData.password) {
        this.$message.warning("请输入密码");
        return false;
      } else if (!this.code) {
        this.$message.warning("请输入验证码");
        return false;
      }
      // else if (!this.registerData.organization_id) {
      //   this.$message.warning("请选择机构");
      //   return false;
      // }
      else if (!this.checkAgreement) {
        this.$message.warning("请勾选协议");
        return false;
      }
      return true;
    },
    // 注册
    async handleRegister() {
      if (this.checkRegisterData()) {
        await checkVerificationCode({
          mobile: this.registerData.mobile,
          type: 1,
          code: this.code,
        });
        let loading = this.$loading({ background: "rgba(0,0,0,0.5)" });
        await userRegister(this.registerData);
        loading.close();
        this.$message.success("注册成功");
        this.popupType = "login";
        this.registerData = {
          mobile: "",
          password: "",
          organization_id: "",
          name: "",
        };
        this.code = "";
      }
    },

    // 验证登录数据
    checkLoginData() {
      if (!this.$tool.verifyPhone(this.loginData.mobile)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      } else if (!this.loginData.password) {
        this.$message.warning("请输入密码");
        return false;
      } else if (!this.checkAgreement) {
        this.$message.warning("请勾选协议");
        return false;
      }
      return true;
    },
    // 登录
    async handleLogin() {
      if (this.checkLoginData()) {
        let loading = this.$loading({ background: "rgba(0,0,0,0.5)" });
        let res = await userLogin(this.loginData);
        loading.close();
        localStorage.set("token", res.data.token);
        this.$store.commit("setAccessToken", res.data.token);
        await this.getUserInfo();
        this.$message.success("登录成功");
        this.loginData = {
          mobile: "",
          password: "",
        };
        this.handleCloseCustomLogin();
      }
    },
    // 关闭弹窗
    handleCloseCustomLogin() {
      this.$store.commit("setCustomLoginVisible", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-login {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  .login-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .login-cont {
    width: 54rem;
    padding: 4rem 2.5rem 3rem;
    background-color: #ffffff;
    border-radius: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .login-title {
      font-size: 2.4rem;
      color: var(--default-color);
      padding-bottom: 1rem;
      line-height: 1;
      border-bottom: 0.5rem solid var(--default-color);
    }

    .form-item {
      width: 100%;
      height: 6rem;
      margin-top: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      img {
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        left: 3.5rem;
        top: 50%;
        transform: translateY(-50%);
      }

      input {
        width: 100%;
        height: 100%;
        border-radius: 3rem;
        border: 0.1rem solid #e4e4e4;
        outline: none;
        padding: 0 3.5rem 0 7.5rem;
        font-size: 1.8rem;
      }

      .screen-cont {
        width: 100%;
        height: 100%;
        border-radius: 3rem;
        border: 0.1rem solid #e4e4e4;
        outline: none;
        padding: 0 3.5rem 0 7.5rem;
        font-size: 1.8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666666;
        cursor: pointer;
        position: relative;

        img {
          width: 2.5rem;
          height: 2.5rem;
          position: static;
          transform: translateY(0);
        }

        .institution-list {
          position: absolute;
          top: 6rem;
          left: 0;
          width: 100%;
          background-color: #ffffff;
          border: 0.1rem solid #efefef;
          border-radius: 1rem;
          max-height: 15rem;
          overflow-y: auto;

          li {
            line-height: 5rem;
            height: 5rem;
            padding: 0 5rem;

            &.active,
            &:hover {
              background-color: var(--default-color);
              color: #ffffff;
            }
          }
        }
      }

      .code-btn {
        width: 17rem;
        text-align: center;
        height: 100%;
        border-radius: 3rem;
        line-height: 5.8rem;
        border: 0.1rem solid var(--default-color);
        color: var(--default-color);
        font-size: 1.8rem;
        cursor: pointer;

        &.disabled {
          border-color: #999999;
          color: #999999;
          cursor: not-allowed;
        }
      }
    }

    .forgot-password {
      font-size: 1.6rem;
      color: var(--default-color);
      margin-right: 2rem;
      margin-top: 1rem;
      margin-bottom: 2.5rem;
      cursor: pointer;
    }

    .submit-btn {
      width: 100%;
      height: 6rem;
      line-height: 6rem;
      text-align: center;
      border-radius: 3rem;
      background-color: var(--default-color);
      color: #ffffff;
      font-size: 1.8rem;
      cursor: pointer;
    }

    .to-register,
    .to-login {
      font-size: 1.6rem;
      margin-top: 1.5rem;
      margin-bottom: 6rem;
      color: var(--default-color);
      cursor: pointer;
    }

    .agreement-cont {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      color: #666666;

      img {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 1rem;
        cursor: pointer;
      }

      .orange {
        color: var(--default-color);
      }

      .pointer {
        cursor: pointer;
      }
    }
  }
}
</style>
