<template>
  <div class="__section-main__" style="background-color: #faf8f5">
    <div
      class="container"
      v-if="detail"
      :style="{
        padding: `${isMobile ? '10rem 5rem 5rem' : '10rem 36rem 5rem'}`,
      }"
    >
      <div class="detail-main">
        <div class="cx-fex cx-fex-itemsc">
          <div class="cx-fex-l cx-fex-column" style="flex: 1">
            <div class="title">{{ detail.title }}</div>
          </div>
        </div>
        <div class="line"></div>

        <div class="content" v-html="detail.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAgreementData } from "@/request/api";
import { mapState } from "vuex";
export default {
  name: "detail",
  data() {
    return {
      detail: null,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  mounted() {
    this.getAgreementData();
  },
  methods: {
    async getAgreementData() {
      let res = await getAgreementData({ classify_id: this.$route.query.id });
      this.detail = res.data;
    },
  },
};
</script>

<style scoped lang="scss">
.detail-main {
  padding: 6rem 12rem 6rem;
  background-color: #ffffff;

  .title {
    font-size: 2.4rem;
    color: #333333;
  }

  .time {
    font-size: 1.6rem;
    color: #999999;
    margin-top: 2.5rem;
  }

  .back {
    padding: 0 4rem;
    color: var(--default-color);
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    cursor: pointer;

    i {
      margin-right: 1rem;
      font-size: 1.8rem;
    }
  }

  .line {
    width: 100%;
    height: 0.1rem;
    background-color: #ececec;
    margin: 3rem 0;
  }

  .content {
    & > div {
      font-size: 1.6rem;
      color: #666666;
      line-height: 4.8rem;
    }
  }

  .bottom-cont {
    margin-top: 6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
    padding-top: 6rem;
    border-top: 0.1rem solid #ececec;
    color: #333333;

    & > div {
      display: flex;
      align-items: center;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: #999999;
      }

      img {
        width: 3rem;
        height: 3rem;
        margin: 0.8rem 1rem 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .detail-main {
    padding: 3rem 5rem 6rem;
    background-color: #ffffff;

    .title {
      font-size: 5rem;
      line-height: 1.5;
    }

    .time {
      font-size: 3.4rem;
      margin-top: 3.5rem;
    }

    .back {
      display: none !important;
    }
  }
}
</style>
