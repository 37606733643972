<template>
  <div class="__section-main__">
    <common-banner :banner-list="bannerList"></common-banner>
    <div class="__container__">
      <div
        v-if="detail"
        class="section-01"
        :class="[isMobile ? 'jl-fex-re-column' : '']"
      >
        <div class="left-cont" :style="{ marginTop: isMobile ? '10rem' : '' }">
          <div class="title">课程介绍</div>
          <div class="desc">
            {{ detail.introduction }}
          </div>
        </div>
        <div
          :style="{
            backgroundImage: `url(${detail.images})`,
          }"
          class="right-cont"
        ></div>
      </div>

      <div class="section-02" v-if="detail">
        <div class="item">
          <div class="image-cont">
            <img
              alt=""
              src="@/assets/images/course-detail-02.png"
              style="width: 5.2rem; height: 5.7rem"
            />
          </div>
          <div class="jl-fex-c jl-fex-item-l jl-fex-column">
            <div class="title">教学时间</div>
            <div class="desc">{{ detail.teaching_days }}天</div>
          </div>
        </div>
        <div class="item">
          <div class="image-cont">
            <img
              alt=""
              src="@/assets/images/course-detail-03.png"
              style="width: 5.4rem; height: 5.9rem"
            />
          </div>
          <div class="jl-fex-c jl-fex-item-l jl-fex-column">
            <div class="title">教学类别</div>
            <div class="desc">{{ detail.teaching_category }}</div>
          </div>
        </div>
      </div>

      <div class="section-03" v-if="detail">
        <div class="course-title-cont">
          <img
            alt=""
            src="@/assets/images/course-detail-04.png"
            style="width: 5.2rem; height: 5.1rem"
          />
          <div>详细介绍</div>
        </div>
        <div class="desc">
          <div v-html="detail.description"></div>
        </div>
      </div>

      <!--      <div class="section-04">-->
      <!--        <div class="course-title-cont">-->
      <!--          <img-->
      <!--            alt=""-->
      <!--            src="@/assets/images/course-detail-05.png"-->
      <!--            style="width: 4rem; height: 4.7rem"-->
      <!--          />-->
      <!--          <div>课程大纲</div>-->
      <!--        </div>-->
      <!--        <div class="desc" v-for="item in 20" :key="item">-->
      <!--          1.1 编程环境的设置-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner";
import { getProductDetail, getBannerList } from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "productDetail",
  components: { CommonBanner },
  data() {
    return {
      bannerList: [],
      detail: null,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  mounted() {
    this.getBannerList();
    this.getProductDetail();
  },
  methods: {
    async getBannerList() {
      let res = await getBannerList({ cate_id: 4 });
      this.bannerList = res.data;
    },
    async getProductDetail() {
      let res = await getProductDetail({ id: this.$route.query.id });
      this.detail = res.data;
      console.log(res);
    },
  },
};
</script>

<style lang="scss" scoped>
.course-title-cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & > div {
    font-size: 4rem;
    margin-left: 2rem;
  }
}

.section-01 {
  padding: 9rem 0 3rem;
  display: flex;
  justify-content: space-between;

  .left-cont {
    flex: 1;
    padding-right: 16rem;

    .title {
      font-size: 4rem;
    }

    .desc {
      font-size: 1.8rem;
      line-height: 2;
      margin-top: 5rem;
    }
  }

  .right-cont {
    width: 79rem;
    height: 46rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.section-02 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f8f8f8;
  padding: 6.5rem 0;

  .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 1;

    .image-cont {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 2.5rem;
    }

    .title {
      font-size: 1.8rem;
      color: #666666;
    }

    .desc {
      font-size: 2.4rem;
      color: #000000;
      margin-top: 2rem;
    }
  }
}

.section-03 {
  margin-top: 6.5rem;
  margin-bottom: 8.5rem;
  .desc {
    font-size: 1.8rem;
    line-height: 2;
    margin-top: 5rem;
    color: #666666;
  }
}

.section-04 {
  margin-bottom: 8.5rem;
  .course-title-cont {
    margin-bottom: 5rem;
  }
  .desc {
    color: #666666;
    font-size: 1.8rem;
    line-height: 3;
  }
}
</style>
