<template>
  <div
    :style="{
      width: `calc(${width} - 3rem)`,
      border: `${showBorder ? '.1rem solid #f4f4f4' : 'none'}`,
      padding: `${isMobile ? '2rem' : '1.5rem'}`,
    }"
    class="teacher-item"
    @click="toTeacherDetailPage(item.id)"
  >
    <div
      :style="{ backgroundImage: `url(${item.headerimg})` }"
      class="image-cont"
    ></div>
    <div class="right-cont">
      <div class="title">{{ item.name }}</div>
      <div class="desc zlc-t-hide3">
        {{ item.description }}
      </div>
      <div v-if="showBtn" class="read-btn">进入主页</div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "teacherItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
    width: {
      type: String,
      default: "33.3333%",
    },
    showBorder: {
      type: Boolean,
      default: false,
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  methods: {
    toTeacherDetailPage(id) {
      this.$router.push({
        path: "/teacherDetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.teacher-item {
  padding: 3rem;
  margin: 1.5rem;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  flex-direction: column;
  border-radius: 1rem;

  &:hover {
    box-shadow: 0 0 5rem rgba(0, 0, 0, 0.09);
  }

  .image-cont {
    width: 100%;
    padding-bottom: 100%;
    border-radius: 100%;
    //height: 25rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .right-cont {
    flex: 1;
    padding: 2rem 0;

    .title {
      font-size: 2.4rem;
      color: #000000;
      font-weight: 600;
      //color: #ffffff;
      text-align: center;
    }

    .desc {
      font-size: 1.6rem;
      color: #7c7c7c;
      //color: #ffffff;
      line-height: 2;
      margin-top: 2.5rem;
    }

    .read-btn {
      margin-top: 3rem;
      width: 16rem;
      height: 4rem;
      line-height: 4rem;
      text-align: center;
      background-color: #ffefe8;
      color: var(--default-color);
      font-size: 1.6rem;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1023px) {
}
</style>
