var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('common-header',[_vm._v("我的课程")]),_c('div',{staticClass:"main-cont"},[_c('div',{staticClass:"nav-cont"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,staticClass:"nav-item",class:{ active: index === _vm.currentNavIndex },on:{"click":function($event){_vm.currentNavIndex = index}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"course-cont"},[(_vm.list.length > 0)?_vm._l((_vm.list),function(item){return _c('div',{key:item.id,staticClass:"course-item",style:({
            width: _vm.isMobile ? `calc(50% - 2rem)` : `calc(33% - 2rem)`,
          }),on:{"click":function($event){return _vm.toCourseDetailPage(item.course.id)}}},[_c('div',{staticClass:"image-cont",style:({
              backgroundImage: `url(${_vm.$tool.getImage(item.course.resource)})`,
            })}),_c('div',{staticClass:"bottom-cont"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.course.name))]),_c('div',{staticClass:"tag-list"},[_c('div',{staticClass:"tag-item"},[_vm._v(_vm._s(item.course_cate_name))])]),_c('div',{staticClass:"user-info"},[_c('div',{staticClass:"avatar",style:({
                  backgroundImage: `url(${item.lecturer_headerimg})`,
                })}),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.lecturer_name))])])])])}):_vm._e(),(_vm.list.length === 0)?_c('div',{staticStyle:{"padding":"10rem 0","width":"100%","text-align":"center","color":"#999999","font-size":"1.8rem"}},[_vm._v(" 暂无数据 ")]):_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }