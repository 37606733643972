<template>
  <div>
    <common-header>用户资料设置</common-header>

    <div class="main-cont">
      <div class="form-item">
        <div class="label">头像：</div>
        <div
          class="avatar"
          :style="{
            backgroundImage: `url(${$tool.getImage(formData.headerimg)})`,
          }"
          @click="handleChooseAvatar()"
        >
          <input
            ref="fileInput"
            type="file"
            accept="image/*"
            style="display: none; width: 100%; height: 100%"
            @change="fileInputChange"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="label">姓名：</div>
        <div class="input-cont">
          <input
            type="text"
            placeholder="请输入姓名"
            v-model="formData.nickname"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="label">绑定手机号：</div>
        <div class="input-cont" v-if="!bindMobileVisible">
          <input
            type="text"
            placeholder="请输入您的手机号"
            disabled
            v-model="userInfo.mobile"
          />
          <div class="code-btn" @click="bindMobileVisible = true">更换绑定</div>
        </div>
        <div class="input-cont" v-if="bindMobileVisible">
          <input
            type="text"
            placeholder="请输入您的手机号"
            v-model="formData.mobile"
          />
          <div class="code-btn" @click="bindMobileVisible = false">
            取消更换
          </div>
        </div>
      </div>
      <div class="form-item" v-if="bindMobileVisible">
        <div class="label">获取验证码：</div>
        <div class="input-cont">
          <input type="text" placeholder="请输入验证码" v-model="code" />
          <div
            :class="{ disabled: disabled }"
            class="code-btn"
            @click="getVerificationCode()"
          >
            {{ disabled ? countDown + "秒后获取" : "获取验证码" }}
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="label">机构名称：</div>
        <div
          class="screen-cont"
          @click="organizationListVisible = !organizationListVisible"
        >
          <div class="name">
            {{
              organizationList.length > 0 && formData.organization_id
                ? organizationList.find(
                    (item) => item.id === formData.organization_id
                  ).name
                : "请选择机构"
            }}
          </div>
          <img src="@/assets/images/login-icon-06.png" alt="" />

          <div class="organization-list" v-if="organizationListVisible">
            <div
              class="organization-item"
              @click.stop="handleChooseOrganization(item.id)"
              :class="{ active: formData.organization_id === item.id }"
              v-for="item in organizationList"
              :key="item.id"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="label"></div>
        <div class="submit-btn" @click="handleSubmit()">确认修改</div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/views/member/components/commonHeader";
import { mapState } from "vuex";
import {
  checkVerificationCode,
  commonUploadImag,
  getOrganizationList,
  getUserInfo,
  getVerificationCode,
  updateUserInfo,
} from "@/request/api";
import localStorage from "@/utils/localStorage";

export default {
  name: "userInfo",
  components: { CommonHeader },
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      formData: {
        nickname: "",
        headerimg: "",
        mobile: "",
        organization_id: "",
      },
      code: "",
      organizationList: [],
      organizationListVisible: false,

      bindMobileVisible: false,

      timer: null,
      countDown: 60,
      lock: true,
      disabled: false,
    };
  },

  mounted() {
    this.formData.nickname = this.userInfo.nickname;
    this.formData.headerimg = this.userInfo.headerimg
      ? this.userInfo.headerimg.input
      : "";
    this.formData.organization_id = this.userInfo.organization_id;
    this.getOrganizationList();
  },
  methods: {
    // 发送验证码
    async getVerificationCode() {
      let mobile = this.formData.mobile;
      let type = 3;

      if (!this.$tool.verifyPhone(mobile)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      }
      if (!this.lock) return;
      if (this.disabled) return;
      this.lock = false;
      setTimeout(() => {
        this.lock = true;
      }, 2000);
      let loading = this.$loading({ background: "rgba(0,0,0,0.5)" });
      await getVerificationCode({ mobile, type });
      loading.close();
      this.$message.success("发送成功");
      clearInterval(this.timer);
      this.disabled = true;
      this.timer = setInterval(() => {
        if (this.countDown <= 1) {
          clearInterval(this.timer);
          this.disabled = false;
          this.countDown = 5;
          return false;
        }
        this.countDown--;
      }, 1000);
    },
    async getUserInfo() {
      let res = await getUserInfo();
      localStorage.set("userInfo", res.data);
      this.$store.commit("setUserInfo", res.data);
      this.formData.nickname = this.userInfo.nickname;
      this.formData.headerimg = this.userInfo.headerimg
        ? this.userInfo.headerimg.input
        : "";
      this.formData.organization_id = this.userInfo.organization_id;
    },
    async handleSubmit() {
      if (this.checkFormData()) {
        let data = {
          nickname: this.formData.nickname,
          headerimg: this.formData.headerimg,
          organization_id: this.formData.organization_id,
          mobile: this.userInfo.mobile,
        };
        if (this.bindMobileVisible) {
          data.mobile = this.formData.mobile;
          await checkVerificationCode({
            mobile: this.formData.mobile,
            type: 3,
            code: this.code,
          });
        }
        await updateUserInfo(data);
        this.$message.success("修改成功");
        this.code = "";
        this.bindMobileVisible = false;
        await this.getUserInfo();
      }
    },
    checkFormData() {
      if (!this.formData.headerimg) {
        this.$message.warning("请上传头像");
        return false;
      } else if (!this.formData.nickname) {
        this.$message.warning("请输入姓名");
        return false;
      } else if (this.bindMobileVisible) {
        if (!this.$tool.verifyPhone(this.formData.mobile)) {
          this.$message.warning("请输入正确的手机号");
          return false;
        } else if (!this.code) {
          this.$message.warning("请输入验证码");
          return false;
        }
      } else if (!this.formData.organization_id) {
        this.$message.warning("请选择机构");
        return false;
      }
      return true;
    },
    async fileInputChange(ev) {
      let formData = new FormData();
      const fileValue = this.$refs.fileInput.files[0];
      formData.append("img", fileValue);
      let res = await commonUploadImag(formData);
      this.formData.headerimg = res.data.input;
    },
    handleChooseAvatar() {
      this.$refs.fileInput.click();
    },
    async getOrganizationList() {
      let res = await getOrganizationList();
      this.organizationList = res.data;
    },
    handleChooseOrganization(id) {
      this.formData.organization_id = id;
      this.organizationListVisible = false;
    },
  },
};
</script>

<style scoped lang="scss">
.main-cont {
  padding: 7.5rem 15.5rem 4rem;
  background-color: #ffffff;

  .form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4rem;

    .label {
      color: #4a4948;
      font-size: 2rem;
      width: 18.5rem;
      text-align: left;
    }

    .avatar {
      width: 9.5rem;
      height: 9.5rem;
      border-radius: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #f7f7f7;
      cursor: pointer;
    }

    .screen-cont {
      width: 40rem;
      height: 5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      border: 0.1rem solid #d4d9de;
      padding: 0 2rem;
      border-radius: 0.5rem;
      cursor: pointer;

      .name {
        color: #666666;
        font-size: 1.8rem;
      }

      img {
        width: 2rem;
        height: 2rem;
      }

      .organization-list {
        width: 100%;
        border-radius: 1rem;
        position: absolute;
        top: 5rem;
        left: 0;
        max-height: 20rem;
        border: 0.1rem solid #efefef;
        background-color: #ffffff;
        overflow-y: auto;

        .organization-item {
          width: 100%;
          height: 5rem;
          line-height: 5rem;
          font-size: 1.6rem;
          padding: 0 2rem;

          &:hover,
          &.active {
            background-color: var(--default-color);
            color: #ffffff;
          }
        }
      }
    }

    .input-cont {
      width: 40rem;
      height: 5rem;
      position: relative;

      input {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        border: 0.1rem solid #d4d9de;
        outline: none;
        padding: 0 2rem;
        font-size: 1.8rem;
      }

      .code-btn {
        width: 12.5rem;
        height: 5rem;
        line-height: 5rem;
        text-align: center;
        background-color: var(--default-color);
        color: #ffffff;
        font-size: 1.8rem;
        border-radius: 0 0.5rem 0.5rem 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;

        &.disabled {
          border-color: #999999;
          color: #999999;
          cursor: not-allowed;
        }
      }
    }

    .submit-btn {
      width: 40rem;
      height: 6rem;
      line-height: 6rem;
      background-color: var(--default-color);
      color: #ffffff;
      text-align: center;
      border-radius: 0.5rem;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}
</style>
