<template>
  <div class="custom-footer" v-if="footData">
    <div class="jl-fex-l jl-fex-item-c">
      <div class="contact">
        <div class="name">联系我们</div>
        <div class="wx-cont">
          <img alt="" src="@/assets/images/wx.png" />
          <div class="code-cont">
            <img :src="footData.qrcode" alt="" />
          </div>
        </div>
      </div>
      <div class="contact-01">
        <div>电话：{{ footData.phone }}</div>
        <div>地址：{{ footData.address }}</div>
      </div>
    </div>

    <a :href="footData.link" target="_blank" class="copy-right">
      {{ footData.name }}
    </a>
  </div>
</template>

<script>
import { getFooterData } from "@/request/api";

export default {
  name: "customFooter",
  data() {
    return {
      footData: null,
    };
  },
  mounted() {
    this.getFootData();
  },
  methods: {
    async getFootData() {
      let res = await getFooterData();
      this.footData = res.data;
      this.$store.commit("setWxCodeImage", res.data.qrcode);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-footer {
  width: 100%;
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #333234;

  .contact {
    font-size: 1.6rem;
    color: #ffffff;
    padding-right: 9rem;
    border-right: 0.3rem solid #ffffff;
    margin-right: 9rem;

    .wx-cont {
      width: 4rem;
      height: 4rem;
      border-radius: 100%;
      border: 0.1rem solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      position: relative;

      img {
        width: 2.2rem;
        height: 1.8rem;
      }

      .code-cont {
        position: absolute;
        bottom: 3.9rem;
        width: 20rem;
        height: 20rem;
        padding: 1rem;
        background-color: #ffffff;
        border-radius: 0.5rem;
        display: none;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        .code-cont {
          display: block;
        }
      }
    }
  }

  .contact-01 {
    font-size: 1.6rem;
    color: #ffffff;

    & > div {
      margin: 1rem 0;
    }
  }

  .copy-right {
    font-size: 1.6rem;
    color: #ffffff;
    margin-top: 3rem;
  }
}
</style>
