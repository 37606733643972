<template>
  <div id="app">
    <custom-header v-if="showWebHeader"></custom-header>
    <router-view/>
    <custom-footer></custom-footer>
  </div>
</template>

<script>
import {remFn} from "@/utils/rem";
import CustomHeader from "@/components/customHeader";
import CustomFooter from "@/components/customFooter";
export default {
  components: {CustomFooter, CustomHeader},
  mounted() {
    this.reloadRem();
    window.addEventListener("resize", this.reloadRem, {passive: false});
  },
  computed:{
    showWebHeader() {
      let _data = false;
      if (this.$route.path !== '/') {
        _data = true
      }
      return _data
    },
  },
  methods:{
    reloadRem() {
      let windowWidth = document.documentElement.getBoundingClientRect().width;
      if (windowWidth <= 750) {
        this.$store.commit('setIsMobile', true);
        remFn(968, 1024)
      } else if (windowWidth < 1024 && windowWidth > 750) {
        this.$store.commit('setIsMobile', true);
        remFn(1920, 1920)
      } else {
        this.$store.commit('setIsMobile', false);
        remFn(1920, 3840)
      }
    },
  }
}
</script>

<style lang="scss">
:root {
  --default-color: #F98D5D;
  --default-title-color: #000000;
  --default-subtitle-color: #333333;
  --default-desc-color: #666666;
}
</style>
