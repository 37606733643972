var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"__section-main__",staticStyle:{"background-color":"#f5f5f5"}},[(_vm.detail)?_c('div',{staticClass:"banner-cont",style:({ height: _vm.isMobile ? '30rem' : '50rem' })},[_c('common-banner',{style:({
        backgroundImage: `url(${_vm.detail.banner})`,
        height: _vm.isMobile ? '30rem' : '50rem',
      })}),_c('div',{staticClass:"teacher-cont"},[_c('div',{staticClass:"jl-fex-l jl-fex-item-c"},[_c('div',{staticClass:"avatar",style:({ backgroundImage: `url(${_vm.detail.headerimg})` })}),_c('div',{staticClass:"word-cont"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.detail.name))]),_c('div',{staticClass:"position"},[_vm._v(_vm._s(_vm.detail.major_name))])])])])],1):_vm._e(),_c('div',{ref:"mainCont",staticClass:"__container__ main-cont",class:[_vm.isMobile ? 'jl-fex-column' : '']},[_c('div',{staticClass:"left-cont"},[(_vm.detail)?_c('div',{staticClass:"teacher-info"},[_c('div',{staticClass:"info-cont"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.detail.name))]),_c('div',{staticClass:"area"},[_c('div',[_vm._v("擅长领域")]),_c('ul',_vm._l((_vm.detail.fields),function(item){return _c('li',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"title"},[_vm._v("讲师简介：")]),_c('div',{staticClass:"jl-fex-l"},_vm._l((_vm.teacherInfoList),function(item){return _c('div',{key:item.id,staticClass:"desc-cont",on:{"click":function($event){return _vm.toTeacherFileDetailPage(item.id)}}},[_c('img',{attrs:{"alt":"","src":require("@/assets/images/word-icon.png")}}),_c('div',{staticClass:"name zlc-t-hide"},[_vm._v(_vm._s(item.name))])])}),0)])]),_c('div',{staticClass:"distinction-cont"},[_c('div',{staticClass:"title"},[_vm._v("讲师特点")]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.detail.description)+" ")])])]):_vm._e(),_c('div',{staticClass:"course-cont"},[_c('div',{staticClass:"title"},[_vm._v("课程列表")]),_c('div',{staticClass:"course-list"},_vm._l((_vm.courseList),function(item){return _c('div',{key:item.id,staticClass:"course-item",on:{"click":function($event){return _vm.toCourseDetailPage(item.id)}}},[_c('div',{staticClass:"image-cont",style:({
                backgroundImage: `url(${item.resource})`,
              })}),_c('div',{staticClass:"jl-fex jl-fex-column",staticStyle:{"flex":"1"}},[_c('div',[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc zlc-t-hide"})]),_c('div',{staticClass:"classify-name"},[_vm._v(_vm._s(item.course_cate_name))])])])}),0),_c('div',{staticClass:"jl-fex-c jl-fex-item-c",staticStyle:{"height":"10rem"}},[_c('el-pagination',{attrs:{"current-page":_vm.courseListQuery.page,"page-size":_vm.courseListQuery.limit,"total":_vm.courseTotal,"background":"","layout":"prev, pager, next"},on:{"update:currentPage":function($event){return _vm.$set(_vm.courseListQuery, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.courseListQuery, "page", $event)},"current-change":_vm.handleCurrentCourseChange}})],1)]),_c('div',{staticClass:"course-cont"},[_c('div',{staticClass:"title"},[_vm._v("近期项目剪影")]),_c('div',{staticClass:"course-list"},_vm._l((_vm.teacherCaseList),function(item){return _c('div',{key:item.id,staticClass:"course-item"},[_c('div',{staticClass:"image-cont",style:({
                backgroundImage: `url(${_vm.$tool.getImage(item.images)})`,
              })}),_c('div',{staticClass:"jl-fex jl-fex-column",staticStyle:{"flex":"1"}},[_c('div',[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc zlc-t-hide"},[_vm._v(" "+_vm._s(item.introduction)+" ")])])])])}),0),_c('div',{staticClass:"jl-fex-c jl-fex-item-c",staticStyle:{"height":"10rem"}},[_c('el-pagination',{attrs:{"current-page":_vm.caseListQuery.page,"page-size":_vm.caseListQuery.limit,"total":_vm.caseTotal,"background":"","layout":"prev, pager, next"},on:{"update:currentPage":function($event){return _vm.$set(_vm.caseListQuery, "page", $event)},"update:current-page":function($event){return _vm.$set(_vm.caseListQuery, "page", $event)},"current-change":_vm.handleCurrentCaseChange}})],1)])]),_c('div',{ref:"rightCont",staticClass:"right-cont",class:[
        { active: _vm.pageScrollTop >= _vm.rangeTop, activeBottom: _vm.bottomVisible },
      ]},[_c('div',{staticClass:"default-cont"},[_vm._m(0),_c('div',{staticClass:"recommend-item"},[_c('div',{staticClass:"name"},[_vm._v("榕师经纪")]),(_vm.detail)?_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.detail.recommend))]):_vm._e()])]),_c('div',{staticClass:"default-cont"},[_vm._m(1),_c('div',{staticClass:"course-list"},_vm._l((_vm.teacherVideoList),function(item,index){return _c('div',{key:item.id,staticClass:"course-item",on:{"click":function($event){return _vm.handleChooseCurrentVideo(index)}}},[_c('div',{staticClass:"image-cont",style:({
                backgroundImage: `url(${item.images})`,
              })}),_c('div',{staticClass:"word-cont"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"time"},[_vm._v(_vm._s(item.create_time))])])])}),0)]),_c('div',{staticClass:"default-cont"},[_c('div',{staticClass:"jl-fex-c jl-fex-item-c"},[_c('div',{staticStyle:{"font-size":"1.6rem","font-weight":"bold","color":"var(--default-color)"}},[_vm._v(" 资质证书及讲师档期请联系您的专属课程顾问 ")]),_c('div',{staticClass:"wx-cont"},[_c('img',{attrs:{"src":require("@/assets/images/wx.png"),"alt":""}}),_c('div',{staticClass:"code-cont"},[_c('img',{attrs:{"src":_vm.wxCodeImage,"alt":""}})])])])])])]),(_vm.videoPopupVisible)?_c('div',{staticClass:"video-popup"},[_c('div',{staticClass:"popup-bg"}),_c('div',{staticClass:"popup-main-cont"},[_c('div',{staticClass:"main-header"},[_vm._v(" "+_vm._s(_vm.currentVideo.name)+" "),_c('img',{attrs:{"src":require("@/assets/images/close-icon.png"),"alt":""},on:{"click":function($event){return _vm.handleCloseVideoPopup()}}})]),_c('div',{staticClass:"video-cont"},[_c('video',{attrs:{"src":_vm.currentVideo.video,"controls":"","controlslist":"nodownload noremoteplayback"}})])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jl-fex-c"},[_c('div',{staticClass:"default-title"},[_vm._v("平台推荐")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"jl-fex-c"},[_c('div',{staticClass:"default-title"},[_vm._v("授课视频")])])
}]

export { render, staticRenderFns }