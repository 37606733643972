import Vue from "vue";
import Vuex from "vuex";
import localStorage from "@/utils/localStorage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
    customLoginVisible: false,
    AccessToken: localStorage.get("token") || "",
    userInfo: localStorage.get("userInfo") || null,
    wxCodeImage: "",
  },
  getters: {},
  mutations: {
    setIsMobile: (state, payload) => {
      state.isMobile = payload;
    },
    setCustomLoginVisible: (state, payload) => {
      state.customLoginVisible = payload;
    },
    setAccessToken: (state, payload) => {
      state.AccessToken = payload;
    },
    setUserInfo: (state, payload) => {
      state.userInfo = payload;
    },
    setWxCodeImage: (state, payload) => {
      state.wxCodeImage = payload;
    },
  },
  actions: {},
  modules: {},
});
