<template>
  <div class="__section-main__">
    <custom-header :show-header="false"></custom-header>
    <index-banner></index-banner>

    <div class="section">
      <div class="__container__">
        <!--        <div class="index-common-title" style="color: #ffffff">月优选讲师</div>-->
        <img
          style="max-width: 100%; height: auto"
          src="@/assets/images/index-teacher.png"
          alt=""
        />
        <div class="jl-fex-l" style="margin-top: 6rem">
          <teacher-item
            v-for="item in teacherList"
            :key="item.id"
            :item="item"
            :width="isMobile ? '50%' : '16.66666%'"
          ></teacher-item>
        </div>

        <div class="jl-fex-c" style="margin-top: 8rem">
          <div class="more-btn" @click="$router.push('/findTeacher')">MORE</div>
        </div>
      </div>
    </div>
    <div class="__container__">
      <div class="section">
        <img
          style="max-width: 100%; height: auto"
          src="@/assets/images/index-course.png"
          alt=""
        />
        <!--        <div class="index-common-title">热门课程</div>-->

        <div class="jl-fex-l" style="margin-top: 10rem">
          <div
            class="course-nav-item"
            v-for="item in courseNavList"
            :key="item.id"
            :style="{ backgroundImage: `url(${item.resource})` }"
            @click="toFindCoursePage(item.id)"
          ></div>
        </div>
      </div>

      <div class="section">
        <img
          style="max-width: 100%; height: auto"
          src="@/assets/images/index-project.png"
          alt=""
        />
        <!--        <div class="index-common-title">近期项目播报</div>-->
        <div class="jl-fex-l" style="margin-top: 10rem">
          <project-item
            v-for="item in newsList"
            :key="item.id"
            :item="item"
          ></project-item>
        </div>
      </div>

      <div class="section">
        <img
          style="max-width: 100%; height: auto"
          src="@/assets/images/index-product.png"
          alt=""
        />
        <!--        <div class="index-common-title">最新产品研发</div>-->
        <div class="jl-fex-l" style="margin-top: 10rem">
          <template v-for="item in productList">
            <product-item
              :key="item.id"
              :item="item"
              :width="isMobile ? '50%' : '25%'"
            ></product-item>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexBanner from "@/components/indexBanner";
import TeacherItem from "@/components/teacherItem";
import ProjectItem from "@/components/projectItem";
import {
  getNewsList,
  getTeacherList,
  getCourseClassifyList,
  getProductList,
} from "@/request/api";
import { mapState } from "vuex";
import ProductItem from "@/components/productItem.vue";
import product from "@/views/Product/index.vue";

export default {
  name: "index",
  components: {
    ProductItem,
    CustomHeader: () => import("@/components/customHeader"),
    ProjectItem,
    TeacherItem,
    IndexBanner,
  },
  data() {
    return {
      currentCourseNavIndex: 0,
      courseNavList: [],

      teacherList: [],
      courseList: [],
      newsList: [],
      productList: [],
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  async mounted() {
    await this.getTeacherList();
    await this.getCourseClassifyList();
    await this.getNewsList();
    await this.getProductList();
  },
  methods: {
    async getProductList() {
      let res = await getProductList({ is_recommend: 1, limit: 4 });
      this.productList = res.data.data;
    },
    toFindCoursePage(id) {
      this.$router.push({
        path: "/findCourse",
        query: {
          cateId: id,
        },
      });
    },
    async getCourseClassifyList() {
      let res = await getCourseClassifyList();
      this.courseNavList = res.data;
    },
    async getTeacherList() {
      let res = await getTeacherList({ is_recommend: 1, limit: 6 });
      this.teacherList = res.data.data;
    },
    async getNewsList() {
      let res = await getNewsList({ is_recommend: 1, page: 1, limit: 3 });
      this.newsList = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  padding: 8rem 0;
}

.index-common-title {
  font-size: 4.8rem;
  color: #000000;
  line-height: 1;
}

.index-common-subtitle {
  font-size: 4.8rem;
  color: #000000;
  font-weight: 600;
  line-height: 1;
  margin-top: 3rem;
}

.index-common-desc {
  font-size: 1.8rem;
  color: #000000;
  line-height: 2;
  margin-top: 8rem;
  width: 50%;
}

.more-btn {
  width: 28.5rem;
  height: 6rem;
  line-height: 6rem;
  text-align: center;
  font-size: 1.6rem;
  color: var(--default-color);
  cursor: pointer;
  border: 0.1rem solid var(--default-color);
}

.course-nav-cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 8rem;
  margin-top: 6rem;
  line-height: 7.8rem;
  border-bottom: 0.1rem solid #ebebeb;
  color: #333333;

  .course-nav-item {
    padding: 0 2rem;
    margin-right: 2rem;
    font-size: 1.8rem;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
    cursor: pointer;

    &.active {
      color: var(--default-color);
      border-bottom: 0.3rem solid var(--default-color);
    }
  }
}

.course-nav-item {
  width: 38.5rem;
  height: 22rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f6f6f6;
  margin-right: 3rem;
  margin-bottom: 3rem;
  cursor: pointer;

  &:nth-of-type(4n) {
    margin-right: 0;
  }
}

@media screen and (max-width: 1023px) {
  .index-common-desc {
    width: 100%;
  }

  .course-nav-item {
    width: calc(50% - 1.5rem);

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}
</style>
