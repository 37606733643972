<template>
  <div class="__section-main__" style="background-color: #f5f5f5">
    <div
      :style="{ height: isMobile ? '30rem' : '50rem' }"
      class="banner-cont"
      v-if="detail"
    >
      <common-banner
        :style="{
          backgroundImage: `url(${detail.banner})`,
          height: isMobile ? '30rem' : '50rem',
        }"
      ></common-banner>
      <div class="teacher-cont">
        <div class="jl-fex-l jl-fex-item-c">
          <div
            :style="{ backgroundImage: `url(${detail.headerimg})` }"
            class="avatar"
          ></div>
          <div class="word-cont">
            <div class="name">{{ detail.name }}</div>
            <div class="position">{{ detail.major_name }}</div>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="mainCont"
      class="__container__ main-cont"
      :class="[isMobile ? 'jl-fex-column' : '']"
    >
      <div class="left-cont">
        <div class="teacher-info" v-if="detail">
          <div class="info-cont">
            <div class="name">{{ detail.name }}</div>
            <div class="area">
              <div>擅长领域</div>
              <ul>
                <li v-for="item in detail.fields" :key="item.id">
                  {{ item.name }}
                </li>
              </ul>
            </div>
            <div class="desc">
              <div class="title">讲师简介：</div>
              <div class="jl-fex-l">
                <div
                  class="desc-cont"
                  v-for="item in teacherInfoList"
                  :key="item.id"
                  @click="toTeacherFileDetailPage(item.id)"
                >
                  <img alt="" src="@/assets/images/word-icon.png" />
                  <div class="name zlc-t-hide">{{ item.name }}</div>
                  <!--                  <div class="time">-->
                  <!--                    {{ item.create_time.substring(0, 10) }}-->
                  <!--                  </div>-->
                </div>
              </div>
            </div>
          </div>
          <div class="distinction-cont">
            <div class="title">讲师特点</div>
            <div class="desc">
              {{ detail.description }}
            </div>
          </div>
        </div>

        <div class="course-cont">
          <div class="title">课程列表</div>
          <div class="course-list">
            <div
              v-for="item in courseList"
              :key="item.id"
              class="course-item"
              @click="toCourseDetailPage(item.id)"
            >
              <div
                :style="{
                  backgroundImage: `url(${item.resource})`,
                }"
                class="image-cont"
              ></div>
              <div class="jl-fex jl-fex-column" style="flex: 1">
                <div>
                  <div class="name">{{ item.name }}</div>
                  <div class="desc zlc-t-hide">
                    <!--                    {{item.}}-->
                  </div>
                </div>
                <div class="classify-name">{{ item.course_cate_name }}</div>
              </div>
            </div>
          </div>
          <div class="jl-fex-c jl-fex-item-c" style="height: 10rem">
            <el-pagination
              :current-page.sync="courseListQuery.page"
              :page-size="courseListQuery.limit"
              :total="courseTotal"
              background
              layout="prev, pager, next"
              @current-change="handleCurrentCourseChange"
            >
            </el-pagination>
          </div>
        </div>

        <div class="course-cont">
          <div class="title">近期项目剪影</div>
          <div class="course-list">
            <div
              v-for="item in teacherCaseList"
              :key="item.id"
              class="course-item"
            >
              <div
                :style="{
                  backgroundImage: `url(${$tool.getImage(item.images)})`,
                }"
                class="image-cont"
              ></div>
              <div class="jl-fex jl-fex-column" style="flex: 1">
                <div>
                  <div class="name">{{ item.name }}</div>
                  <div class="desc zlc-t-hide">
                    {{ item.introduction }}
                  </div>
                </div>
                <!--  <div class="classify-name">企业经营</div>-->
              </div>
            </div>
          </div>
          <div class="jl-fex-c jl-fex-item-c" style="height: 10rem">
            <el-pagination
              :current-page.sync="caseListQuery.page"
              :page-size="caseListQuery.limit"
              :total="caseTotal"
              background
              layout="prev, pager, next"
              @current-change="handleCurrentCaseChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <div
        class="right-cont"
        ref="rightCont"
        :class="[
          { active: pageScrollTop >= rangeTop, activeBottom: bottomVisible },
        ]"
      >
        <div class="default-cont">
          <div class="jl-fex-c">
            <div class="default-title">平台推荐</div>
          </div>
          <div class="recommend-item">
            <div class="name">榕师经纪</div>
            <div class="desc" v-if="detail">{{ detail.recommend }}</div>
            <!--            <div class="time">2024-01-12 12:22:22</div>-->
          </div>
        </div>

        <div class="default-cont">
          <div class="jl-fex-c">
            <div class="default-title">授课视频</div>
          </div>
          <div class="course-list">
            <div
              class="course-item"
              v-for="(item, index) in teacherVideoList"
              :key="item.id"
              @click="handleChooseCurrentVideo(index)"
            >
              <div
                class="image-cont"
                :style="{
                  backgroundImage: `url(${item.images})`,
                }"
              ></div>
              <div class="word-cont">
                <div class="title">{{ item.name }}</div>
                <div class="time">{{ item.create_time }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="default-cont">
          <div class="jl-fex-c jl-fex-item-c">
            <div
              style="
                font-size: 1.6rem;
                font-weight: bold;
                color: var(--default-color);
              "
            >
              资质证书及讲师档期请联系您的专属课程顾问
            </div>
            <div class="wx-cont">
              <img src="@/assets/images/wx.png" alt="" />
              <div class="code-cont">
                <img :src="wxCodeImage" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="video-popup" v-if="videoPopupVisible">
      <div class="popup-bg"></div>
      <div class="popup-main-cont">
        <div class="main-header">
          {{ currentVideo.name }}
          <img
            @click="handleCloseVideoPopup()"
            src="@/assets/images/close-icon.png"
            alt=""
          />
        </div>
        <div class="video-cont">
          <video
            :src="currentVideo.video"
            controls
            controlslist="nodownload noremoteplayback"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner";
import {
  getTeacherDetail,
  getTeacherIntroductionList,
  getTeacherCaseList,
  getCourseList,
  getTeacherVideoList,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "teacherDetail",
  components: { CommonBanner },
  data() {
    return {
      detail: null,
      bannerList: [],
      teacherCaseList: [],
      caseTotal: null,
      caseListQuery: {
        page: 1,
        limit: 6,
      },

      courseList: [],
      courseListQuery: {
        page: 1,
        limit: 6,
      },
      courseTotal: null,

      teacherInfoList: [],
      teacherVideoList: [],

      videoPopupVisible: false,
      currentVideo: null,

      rangeTop: 1,
      pageScrollTop: 0,
      pageHeight: 0,
      bottomVisible: false,
    };
  },
  computed: {
    ...mapState(["isMobile", "wxCodeImage"]),
  },
  async mounted() {
    await this.getTeacherDetail();
    if (!this.isMobile) {
      this.handleListenScroll();
    }
  },
  methods: {
    handleListenScroll() {
      const that = this;
      let pageHeight =
        document.documentElement.offsetHeight || document.body.offsetHeight;
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let rangeHeight = pageHeight - clientHeight - 140;
      this.rangeTop = this.$refs.mainCont.getBoundingClientRect().top;
      window.addEventListener(
        "scroll",
        function (ev) {
          that.pageScrollTop =
            document.documentElement.scrollTop || document.body.scrollTop;
          that.bottomVisible = rangeHeight <= that.pageScrollTop;
        },
        true
      );
    },

    toCourseDetailPage(id) {
      this.$router.push({
        path: "/courseDetail",
        query: {
          id,
        },
      });
    },
    handleCloseVideoPopup() {
      this.currentVideo = null;
      this.videoPopupVisible = false;
    },
    handleChooseCurrentVideo(index) {
      this.currentVideo = this.teacherVideoList[index];
      this.videoPopupVisible = true;
    },
    async getTeacherVideoList() {
      let res = await getTeacherVideoList({
        page: 1,
        limit: 999,
        lecturer_id: this.$route.query.id,
      });
      this.teacherVideoList = res.data.data;
    },
    toTeacherFileDetailPage(id) {
      this.$router.push({
        path: "/teacherFileDetail",
        query: {
          id,
        },
      });
    },
    async getTeacherIntroductionList(id) {
      let res = await getTeacherIntroductionList({
        lecturer_id: this.$route.query.id,
      });
      this.teacherInfoList = res.data;
    },
    handleCurrentCourseChange(val) {
      this.courseListQuery.page = val;
      this.getCourseList();
    },
    async getCourseList() {
      let res = await getCourseList({
        cate_id: "",
        page: this.courseListQuery.page,
        limit: this.courseListQuery.limit,
        lecturer_id: this.$route.query.id,
      });
      this.courseTotal = res.data.total;
      this.courseList = res.data.data;
    },
    handleCurrentCaseChange(val) {
      this.caseListQuery.page = val;
      this.getTeacherCaseList();
    },
    async getTeacherCaseList() {
      let res = await getTeacherCaseList({
        lecturer_id: this.detail.id,
        page: this.caseListQuery.page,
        limit: this.caseListQuery.limit,
      });

      this.teacherCaseList = res.data.data;
      this.caseTotal = res.data.total;
    },
    async getTeacherDetail() {
      let res = await getTeacherDetail({ id: this.$route.query.id });
      this.detail = res.data;
      await this.getTeacherIntroductionList();
      await this.getTeacherVideoList();
      await this.getTeacherCaseList();
      await this.getCourseList();
    },
  },
};
</script>

<style lang="scss" scoped>
.video-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  .popup-bg {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
  }

  .popup-main-cont {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80rem;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    overflow: hidden;

    .main-header {
      padding: 0 5rem;
      width: 100%;
      position: relative;
      height: 8rem;
      text-align: center;
      line-height: 8rem;
      font-size: 1.8rem;

      img {
        width: 3rem;
        height: 3rem;
        position: absolute;
        right: 3rem;
        top: 2.5rem;
        cursor: pointer;
      }
    }

    .video-cont {
      width: 100%;
      height: 60rem;
      background-color: #1b1b1b;

      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.banner-cont {
  width: 100%;
  position: relative;

  .teacher-cont {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 15.5rem;
    width: calc(100% - 29rem);
    margin: 0 14.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 2rem 6rem;

    .avatar {
      width: 11.5rem;
      height: 11.5rem;
      border-radius: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .word-cont {
      color: #ffffff;
      line-height: 1;
      padding-left: 2.5rem;

      .name {
        font-size: 3rem;
      }

      .position {
        font-size: 1.8rem;
        margin-top: 2rem;
      }
    }
  }
}

.main-cont {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0 0;
  padding-bottom: 8rem !important;

  .left-cont {
    width: 110.5rem;

    .teacher-info {
      width: 100%;
      padding: 2rem 0;
      border-radius: 0.5rem;
      background-color: #ffffff;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10rem;

      .info-cont {
        width: 50%;
        padding: 0 5rem;
        border-right: 0.1rem solid #e7e7e7;

        .name {
          font-size: 3.6rem;
          font-weight: bold;
          padding-top: 3rem;
          line-height: 1;
        }

        .area {
          display: flex;
          justify-content: flex-start;
          margin-top: 4rem;

          & > div {
            font-size: 1.8rem;
            padding-right: 2.4rem;
            line-height: 3rem;
          }

          & > ul {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            li {
              height: 3rem;
              border-radius: 1.5rem;
              padding: 0 1.5rem;
              color: var(--default-color);
              border: 0.1rem solid var(--default-color);
              font-size: 1.6rem;
              line-height: 2.8rem;
              margin-right: 1rem;
              margin-bottom: 1rem;
            }
          }
        }

        .desc {
          margin-top: 4.5rem;

          .title {
            font-size: 1.8rem;
          }

          .desc-cont {
            width: 18rem;
            height: 16rem;
            margin-top: 2rem;
            box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.06);
            padding: 2rem;
            margin-right: 2rem;
            cursor: pointer;

            img {
              width: 3.4rem;
              height: 3.8rem;
            }

            .name,
            .time {
              font-size: 1.7rem;
              line-height: 1;
              margin-top: 1rem;
            }

            .name {
              margin-top: 5rem;
              padding-top: 0;
            }
          }
        }
      }

      .distinction-cont {
        width: 50%;
        padding: 0 5rem;

        .title {
          padding-top: 3rem;
          font-size: 3rem;
          font-weight: bold;
          margin-bottom: 4rem;
          line-height: 1;
        }

        .desc {
          font-size: 1.6rem;
          color: #999999;
          line-height: 2;
        }
      }
    }

    .course-cont {
      width: 100%;
      padding: 4rem 5rem;
      border-radius: 0.5rem;
      background-color: #ffffff;
      margin-top: 2rem;

      .title {
        font-size: 2.6rem;
        font-weight: bold;
      }

      .course-item {
        margin-top: 4rem;
        display: flex;
        justify-content: flex-start;
        cursor: pointer;

        .image-cont {
          width: 26rem;
          height: 18rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          margin-right: 3rem;
          border-radius: 0.5rem;
        }

        .name {
          font-size: 2.2rem;
        }

        .desc {
          font-size: 1.6rem;
          color: #999999;
          margin-top: 2rem;
        }

        .classify-name {
          font-size: 1.6rem;
          color: #999999;
          margin-bottom: 2rem;
        }
      }

      .video-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .video-item {
        width: calc((100% - 8rem) / 3);
        margin-right: 4rem;
        margin-top: 4rem;
        border: 0.1rem solid #e5e5e5;
        border-radius: 0.5rem;

        .image-cont {
          width: 100%;
          height: 17.5rem;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border-radius: 0.5rem 0.5rem 0 0;
        }

        .word-cont {
          padding: 3rem 1.5rem;

          .name {
            font-size: 2.4rem;
            line-height: 1.5;
          }

          .time {
            font-size: 1.6rem;
            color: #999999;
            margin-top: 4rem;
          }
        }

        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
  }

  .right-cont {
    width: 48.5rem;
    position: relative;
    &.active {
      position: fixed;
      top: 14rem;
      right: 14.5rem !important;
      //margin-bottom: 26rem;

      &.activeBottom {
        top: inherit;
        bottom: 26rem;
      }
    }

    .default-cont {
      width: 100%;
      background: #ffffff;
      border-radius: 0 0 0.5rem 0.5rem;
      border-top: 0.1rem solid var(--default-color);
      padding: 4rem 1rem 4rem;
      margin-bottom: 2rem;

      .default-title {
        font-size: 2.4rem;
        color: #000000;
        padding: 0 0.8rem;
        border-bottom: 1rem solid #ffc5ab;
        line-height: 0.58;
      }

      .recommend-item {
        padding: 3rem;
        border-bottom: 0.1rem solid #e7e7e7;

        .name {
          font-size: 2rem;
          font-weight: bold;
        }

        .desc {
          font-size: 1.6rem;
          color: #515659;
          margin-top: 2rem;
        }

        .time {
          font-size: 1.6rem;
          color: #515659;
          margin-top: 1.5rem;
        }
      }

      .certificate-item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 5.5rem;
        margin-top: 5rem;

        .image-cont {
          width: 100%;
          padding-bottom: 75%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }

        .name {
          font-size: 1.6rem;
          color: #999999;
          margin-top: 2rem;
        }
      }

      .course-list {
        padding: 4.5rem;
        max-height: 30rem;
        overflow-y: auto;

        .course-item {
          border: 0.1rem solid #e5e5e5;
          border-radius: 0.5rem;
          margin-bottom: 1rem;

          .image-cont {
            width: 100%;
            padding-bottom: 50%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            border-radius: 0.5rem 0.5rem 0 0;
          }

          .word-cont {
            padding: 2rem;

            .title {
              font-size: 2.4rem;
              color: #1b1b1b;
              line-height: 1.5;
            }

            .time {
              font-size: 1.6rem;
              color: #999999;
              margin-top: 3rem;
            }
          }
        }
      }
    }
  }
}

.wx-cont {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  background-color: var(--default-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
  position: relative;

  img {
    width: 2.2rem;
    height: 1.8rem;
  }

  .code-cont {
    position: absolute;
    bottom: 2.9rem;
    width: 20rem;
    height: 20rem;
    padding: 1rem;
    background-color: #ffffff;
    border-radius: 0.5rem;
    display: none;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    .code-cont {
      display: block;
    }
  }
}

@media screen and (max-width: 1023px) {
  .main-cont {
    .left-cont {
      width: 100%;

      .teacher-info {
        flex-direction: column;

        .info-cont {
          width: 100%;
          border-right: none;
        }

        .distinction-cont {
          width: 100%;
          margin-top: 5rem;
        }
      }
    }

    .right-cont {
      margin-top: 5rem;
      width: 100%;
    }
  }
}
</style>
