import request from "@/utils/request";

// 登录
export const userLogin = (data) => {
  return request.post("/api/login", data);
};
// 退出
export const userExit = (data) => {
  return request.post("/api/getUsersTokenDel", data);
};
// 注册
export const userRegister = (data) => {
  return request.post("/api/register", data);
};
// 验证验证码
export const checkVerificationCode = (data) => {
  return request.post("/api/verificationCode", data);
};
// 获取验证码
export const getVerificationCode = (data) => {
  return request.post("/api/sendCode", data);
};
// 忘记密码
export const forgotPwd = (data) => {
  return request.post("/api/forget", data);
};

//获取用户信息
export const getUserInfo = (data) => {
  return request.post("/api/getUsersFind", data);
};
//获取用户信息
export const updateUserInfo = (data) => {
  return request.post("/api/getUsersUpdate", data);
};

//修改用户密码
export const updatePassword = (data) => {
  return request.post("/api/getUsersPasswordUpdate", data);
};

// 获取机构列表
export const getOrganizationList = (data) => {
  return request.post("/api/getOrganizationList", data);
};
// 用户添加课程
export const addUserCourse = (data) => {
  return request.post("/api/usersCourseCreate", data);
};

// 用户添加课程
export const getUserCourseList = (data) => {
  return request.post("/api/getUsersCourseList", data);
};

// 获取首页公共数据
export const getBannerList = (data) => {
  return request.post("/api/banner", data);
};

// 获取讲师列表
export const getTeacherList = (data) => {
  return request.post("/api/getLecturerList", data);
};
// 获取讲师详情
export const getTeacherDetail = (data) => {
  return request.post("/api/getLecturerFind", data);
};
// 获取讲师成功案例列表
export const getTeacherCaseList = (data) => {
  return request.post("/api/getLecturerCasesList", data);
};

// 获取讲师简介列表
export const getTeacherIntroductionList = (data) => {
  return request.post("/api/getIntroductionList", data);
};

// 获取讲师简介详情
export const getTeacherIntroductionDetail = (data) => {
  return request.post("/api/getIntroductionFind", data);
};

// 获取讲师视频列表
export const getTeacherVideoList = (data) => {
  return request.post("/api/getLecturerVideoList", data);
};

// 获取课程分类列表
export const getCourseClassifyList = (data) => {
  return request.post("/api/getCourseCateList", data);
};
// 获取课程列表
export const getCourseList = (data) => {
  return request.post("/api/getCourseList", data);
};
// 获取课程详情
export const getCourseDetail = (data) => {
  return request.post("/api/getCourseFind", data);
};

// 获取老师课程简介
export const getTeacherInfoByCourse = (data) => {
  return request.post("/api/getCourseIntroductionFind", data);
};

// 获取项目播报列表
export const getNewsList = (data) => {
  return request.post("/api/getBroadcastList", data);
};
// 获取项目播报详情
export const getNewsDetail = (data) => {
  return request.post("/api/getBroadcastFind", data);
};

// 获取产品列表
export const getProductList = (data) => {
  return request.post("/api/getProductList", data);
};

//获取产品详情
export const getProductDetail = (data) => {
  return request.post("/api/getProductFind", data);
};

//获取产品详情
export const commonUploadImag = (data) => {
  return request.post("/api/upload-img", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// 获取底部数据
export const getFooterData = (data) => {
  return request.post("/api/getBottomFind", data);
};

// 获取协议
export const getAgreementData = (data) => {
  return request.post("/api/articlesFind", data);
};
