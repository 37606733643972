<template>
  <div
    :style="{
      backgroundImage: `url(${
        bannerList.length > 0 && bannerList[0].resource
      })`,
    }"
    class="common-banner"
  ></div>
</template>

<script>
export default {
  name: "commonBanner",
  props: {
    bannerList: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.common-banner {
  width: 100%;
  height: 56rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 1023px) {
  .common-banner {
    height: 30rem;
  }
}
</style>
