<template>
<div class="common-header">
  <div class="title-cont"><slot /></div>
</div>
</template>

<script>
export default {
  name: "commonHeader"
}
</script>

<style scoped lang="scss">
.common-header{
  width: 100%;
  height: 8rem;
  background-color: #ffffff;
  display: flex;
  margin-bottom: 2rem;
  .title-cont{
    padding: 0 2.5rem;
    font-size: 2rem;
    line-height: 8rem;
    color: #4A4948;
    border-bottom: .3rem solid var(--default-color);
  }
}
</style>
