<template>
  <div class="custom-search">
    <div class="search-cont">
      <input :placeholder="placeholder" type="text" v-model="keyword" />
      <div class="search-btn" @click="handleSearch">
        <img :src="require('@/assets/images/search-icon.png')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "customSearch",
  props: {
    placeholder: {
      type: String,
      default: "请输入",
    },
  },
  data() {
    return {
      keyword: "",
    };
  },
  mounted() {
    this.keyword = this.$route.query.keyword ? this.$route.query.keyword : "";
  },
  watch: {
    "$route.query.keyword"(e) {
      if (e) {
        this.keyword = e;
        this.$emit("handleSearchConfirm", this.keyword);
      }
    },
  },
  methods: {
    handleSearch() {
      this.$emit("handleSearchConfirm", this.keyword);
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-search {
  margin-top: 5rem;
  display: flex;
  justify-content: flex-start;

  .search-cont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.06);

    input {
      width: 55rem;
      height: 9rem;
      padding: 0 3rem;
      font-size: 1.6rem;
      border: none;
      outline: none;
    }

    .search-btn {
      width: 9rem;
      height: 9rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--default-color);
      cursor: pointer;

      img {
        width: 3rem;
        height: 3rem;
      }
    }
  }
}
</style>
