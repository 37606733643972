<template>
  <div class="__section-main__">
    <common-banner :bannerList="bannerList"></common-banner>
    <div class="__container__">
      <custom-search
        placeholder="请输入讲师信息查询"
        @handleSearchConfirm="handleSearchConfirm"
      ></custom-search>
      <template v-if="list.length > 0">
        <div class="jl-fex-l" style="margin-top: 5rem">
          <template v-for="item in list">
            <teacher-item
              :key="item.id"
              :item="item"
              :show-btn="true"
              :showBorder="true"
              :width="isMobile ? '100%' : '16.6666%'"
            ></teacher-item>
          </template>
        </div>
      </template>
      <div
        v-if="list.length === 0"
        class="jl-fex-c jl-fex-item-c"
        style="height: 40rem; font-size: 2rem; color: #999999"
      >
        暂无数据
      </div>
    </div>
    <div
      v-if="list.length > 0"
      class="jl-fex-c jl-fex-item-c"
      style="height: 20rem"
    >
      <el-pagination
        :current-page.sync="listQuery.page"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner";
import CustomSearch from "@/components/customSearch";
import TeacherItem from "@/components/teacherItem";
import { getBannerList, getTeacherList } from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "findTeacher",
  components: { TeacherItem, CustomSearch, CommonBanner },
  data() {
    return {
      keyword: "",
      bannerList: [],
      list: [],
      listQuery: {
        page: 1,
        limit: 18,
      },
      total: null,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  mounted() {
    this.keyword = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.getBannerList();
    this.getTeacherList();
  },
  methods: {
    getInitData() {
      this.list = [];
      this.listQuery.page = 1;
      this.total = null;
    },
    handleSearchConfirm(ev) {
      this.getInitData();
      this.keyword = ev;
      this.getTeacherList();
    },
    async getBannerList() {
      let res = await getBannerList({ cate_id: 2 });
      this.bannerList = res.data;
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getTeacherList();
    },
    async getTeacherList() {
      let res = await getTeacherList({
        page: this.listQuery.page,
        limit: this.listQuery.limit,
        name: this.keyword,
      });
      this.total = res.data.total;
      this.list = res.data.data;
    },
  },
};
</script>

<style scoped></style>
