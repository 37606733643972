<template>
  <div
    class="__section-main__"
    style="background-color: #f5f5f5; padding-top: 4.5rem"
  >
    <div class="__container__">
      <div class="user-cont" v-if="teacherDetail">
        <div
          class="avatar"
          :style="{
            backgroundImage: `url(${teacherDetail.lecturer_headerimg})`,
          }"
        ></div>
        <div class="right-cont">
          <div class="name">{{ teacherDetail.lecturer_name }}</div>
          <div
            v-if="teacherDetail.introduction_id"
            class="file-cont"
            @click="toTeacherFileDetailPage(teacherDetail.introduction_id)"
          >
            <img class="file-icon" src="@/assets/images/word-icon.png" alt="" />
            <div class="word-cont">
              <div class="file-name">{{ teacherDetail.introduction_name }}</div>
              <!--              <div>{{ teacherDetail.introduction_time }}</div>-->
            </div>
          </div>
        </div>
      </div>

      <div class="course-cont" v-if="detail">
        <div class="course-header">
          <div class="name">
            {{ detail.name }}
          </div>
          <div class="icon-cont">
            <img
              @click="handleDownloadFile()"
              v-if="AccessToken && userInfo && userInfo.type === 1"
              src="@/assets/images/down-icon.png"
              alt=""
            />
          </div>
        </div>

        <div class="main-cont">
          <div class="preview-pdf-cont" v-if="isPdf">
            <iframe
              id="pdfIframe"
              :src="blobUrl + '#toolbar=0'"
              @contextmenu="
                () => {
                  return false;
                }
              "
            >
            </iframe>
            <div class="mark" @contextmenu.prevent="() => {}"></div>
          </div>
          <div class="preview-pdf-cont" v-if="isPPT || isExcel || isDocx">
            <iframe
              id="PPTIframe"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                detail.courseware
              "
            >
            </iframe>
            <div v-if="isPPT" class="tool-ppt-mark"></div>
            <div v-if="isExcel" class="tool-excel-mark"></div>
            <div v-if="isDocx" class="tool-word-mark"></div>
            <div v-if="isDocx" class="content-mark"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addUserCourse,
  getCourseDetail,
  getTeacherInfoByCourse,
} from "@/request/api";
import VueOfficeDocx from "@vue-office/docx";
import "@vue-office/docx/lib/index.css";
import { mapState } from "vuex";
import VueOfficeExcel from "@vue-office/excel";
import "@vue-office/excel/lib/index.css";
export default {
  name: "courseDetail",
  components: {},
  data() {
    return {
      detail: null,
      isDocx: false,
      isPdf: false,
      isExcel: false,
      isPPT: false,

      teacherDetail: null,
      blobUrl: "",

      docContent: null,
    };
  },
  computed: {
    ...mapState(["AccessToken", "userInfo"]),
  },
  mounted() {
    this.getCourseDetail();
  },
  methods: {
    loadPPT() {},
    // pdf获取blob文件流
    async getBlobUrl() {
      const response = await fetch(this.detail.courseware);
      const blob = await response.blob();
      this.blobUrl = window.URL.createObjectURL(blob);
      console.log(this.blobUrl);
    },
    toTeacherFileDetailPage(id) {
      this.$router.push({
        path: "/teacherFileDetail",
        query: {
          id,
        },
      });
    },
    // 下载文件
    async handleDownloadFile() {
      const response = await fetch(this.detail.courseware);
      const blob = await response.blob();
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = this.detail.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(a.href);
      document.body.removeChild(a);
      try {
        await addUserCourse({ course_id: this.detail.id });
      } catch (err) {
        console.log(err);
      }
    },

    async getTeacherInfoByCourse(id) {
      let res = await getTeacherInfoByCourse({ id });
      this.teacherDetail = res.data;
    },
    async getCourseDetail() {
      let res = await getCourseDetail({ id: this.$route.query.id });
      this.detail = res.data;

      this.previewFile(res.data.courseware);

      await this.getTeacherInfoByCourse(res.data.id);
    },
    previewFile(url) {
      // 根据文件格式显示预览内容
      const fileExtension = url.split(".").pop().toLowerCase();
      console.log(fileExtension);
      if (fileExtension === "docx" || fileExtension === "doc") {
        this.getBlobUrl();
        this.isDocx = true;
      } else if (fileExtension === "pdf" || fileExtension === "PDF") {
        this.isPdf = true;
        this.getBlobUrl();
      } else if (fileExtension === "xlsx" || fileExtension === "xls") {
        this.getBlobUrl();
        this.isExcel = true;
      } else if (fileExtension === "ppt" || fileExtension === "pptx") {
        this.getBlobUrl();
        this.isPPT = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-pdf-cont {
  position: relative;
  width: 100%;
  height: calc(100vh - 14rem);

  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }

  .mark {
    width: calc(100% - 10px);
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .tool-ppt-mark {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 48px;
    background-color: #000000;
  }
  .tool-excel-mark {
    position: absolute;
    width: 100%;
    height: 155px;
    top: 0;
    background-color: #f5f5f5;
  }
  .tool-word-mark {
    position: absolute;
    width: 100%;
    height: 40px;
    top: 48px;
    background-color: #f5f5f5;
  }

  .content-mark {
    position: absolute;
    width: calc(100% - 10px);
    height: calc(100% - 48px);
    bottom: 0;
    left: 0;
  }
}
.user-cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  padding: 2.5rem 6rem;

  .avatar {
    width: 16rem;
    height: 16rem;
    border-radius: 100%;
    margin-right: 8rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .right-cont {
    flex: 1;

    .name {
      font-size: 3.6rem;
      font-weight: bold;
    }

    .file-cont {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #f8f8f8;
      padding: 1.5rem 2rem;
      margin-top: 2rem;
      font-size: 1.6rem;
      cursor: pointer;

      .file-icon {
        width: 3.4rem;
        height: 3.8rem;
        margin-right: 1.5rem;
      }
    }
  }
}
.course-cont {
  //height: 10rem;
  width: 100%;
  background-color: #e1e1e1;
  margin-top: 4.5rem;

  .course-header {
    height: 10rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #a0a0a0;
    padding: 0 4.5rem;

    .name {
      font-size: 2rem;
      color: #ffffff;
    }

    .icon-cont {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      img {
        width: 2.7rem;
        height: 2.7rem;
        cursor: pointer;
      }
    }
  }

  .main-cont {
    padding: 4rem 19rem;
  }
}
@media screen and (max-width: 1023px) {
  .course-cont {
    .main-cont {
      padding: 4rem;
    }
  }
}
</style>
