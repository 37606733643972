<template>
  <div class="__section-main__">
    <common-banner :bannerList="bannerList"></common-banner>

    <div class="__container__">
      <custom-search
        placeholder="请输入课程信息查询"
        @handleSearchConfirm="handleSearchConfirm"
      ></custom-search>

      <div class="classify-cont">
        <div class="name">课程分类：</div>
        <div class="classify-list">
          <div
            v-for="(item, index) in classifyList"
            :key="item.id"
            :class="{ active: currentClassifyIndex === index }"
            class="classify-item"
            @click="currentClassifyIndex = index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="word-cont" v-if="classifyList.length > 0">
        <div class="title">{{ classifyList[currentClassifyIndex].name }}</div>
        <div class="desc">系列课程带你一路畅通</div>
      </div>
      <template v-if="list.length > 0">
        <div class="jl-fex-l">
          <template v-for="item in list">
            <course-item
              :key="item.id"
              :item="item"
              :width="isMobile ? '50%' : '25%'"
            ></course-item>
          </template>
        </div>
      </template>
      <div
        v-if="list.length === 0"
        class="jl-fex-c jl-fex-item-c"
        style="height: 40rem; font-size: 2rem; color: #999999"
      >
        暂无数据
      </div>
    </div>

    <div
      v-if="list.length > 0"
      class="jl-fex-c jl-fex-item-c"
      style="height: 20rem"
    >
      <el-pagination
        :current-page.sync="listQuery.page"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner";
import CustomSearch from "@/components/customSearch";
import CourseItem from "@/components/courseItem";
import {
  getBannerList,
  getCourseClassifyList,
  getCourseList,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "findCourse",
  components: { CourseItem, CustomSearch, CommonBanner },
  data() {
    return {
      bannerList: [],
      classifyList: [],
      currentClassifyIndex: 0,
      keyword: "",
      list: [],
      listQuery: {
        page: 1,
        limit: 20,
      },
      total: null,
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  watch: {
    async currentClassifyIndex(e) {
      this.getInitData();
      await this.getCourseList();
    },
  },
  mounted() {
    this.keyword = this.$route.query.keyword ? this.$route.query.keyword : "";
    this.getBannerList();
    this.getCourseClassifyList();
  },
  methods: {
    handleSearchConfirm(ev) {
      this.getInitData();
      this.keyword = ev;
      this.getCourseList();
    },
    async getBannerList() {
      let res = await getBannerList({ cate_id: 2 });
      this.bannerList = res.data;
    },
    getInitData() {
      this.list = [];
      this.listQuery.page = 1;
      this.total = null;
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getCourseList();
    },
    async getCourseClassifyList() {
      let res = await getCourseClassifyList({ status: 1 });
      let classifyList = res.data;
      classifyList.unshift({
        name: "全部",
        id: "",
      });
      this.classifyList = classifyList;
      if (this.$route.query.cateId) {
        this.currentClassifyIndex = this.classifyList.findIndex(
          (item) => item.id * 1 === this.$route.query.cateId * 1
        );
      }
      await this.getCourseList();
    },
    async getCourseList() {
      let res = await getCourseList({
        cate_id: this.classifyList[this.currentClassifyIndex].id,
        page: this.listQuery.page,
        limit: this.listQuery.limit,
        name: this.keyword,
      });
      this.total = res.data.total;
      this.list = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.classify-cont {
  margin-top: 3rem;
  background-color: #f5f7fa;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 4rem 0;

  .name {
    font-size: 2.2rem;
    color: #333234;
    line-height: 4rem;
  }

  .classify-list {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .classify-item {
      background-color: transparent;
      height: 4rem;
      line-height: 4rem;
      font-size: 1.6rem;
      color: #666666;
      padding: 0 2.5rem;
      margin-bottom: 4rem;
      margin-right: 1rem;
      cursor: pointer;
      transition: all 0.3s;

      &.active,
      &:hover {
        color: #ffffff;
        background-color: var(--default-color);
      }
    }
  }
}

.word-cont {
  display: flex;
  align-items: flex-end;
  padding: 4rem 0;
  line-height: 1;

  .title {
    font-size: 3rem;
    color: #000000;
  }

  .desc {
    font-size: 1.6rem;
    color: #666666;
    margin-left: 3rem;
  }
}
</style>
