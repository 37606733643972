<template>
  <div>
    <common-header>我的课程</common-header>
    <div class="main-cont">
      <div class="nav-cont">
        <div
          v-for="(item, index) in navList"
          :key="index"
          :class="{ active: index === currentNavIndex }"
          class="nav-item"
          @click="currentNavIndex = index"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="course-cont">
        <template v-if="list.length > 0">
          <div
            v-for="item in list"
            :key="item.id"
            :style="{
              width: isMobile ? `calc(50% - 2rem)` : `calc(33% - 2rem)`,
            }"
            class="course-item"
            @click="toCourseDetailPage(item.course.id)"
          >
            <div
              :style="{
                backgroundImage: `url(${$tool.getImage(item.course.resource)})`,
              }"
              class="image-cont"
            ></div>
            <div class="bottom-cont">
              <div class="title">{{ item.course.name }}</div>
              <div class="tag-list">
                <div class="tag-item">{{ item.course_cate_name }}</div>
                <!--        <div class="tag-item">名师讲堂</div>-->
              </div>
              <div class="user-info">
                <div
                  :style="{
                    backgroundImage: `url(${item.lecturer_headerimg})`,
                  }"
                  class="avatar"
                ></div>
                <div class="name">{{ item.lecturer_name }}</div>
              </div>
            </div>
          </div>
        </template>
        <div
          v-if="list.length === 0"
          style="
            padding: 10rem 0;
            width: 100%;
            text-align: center;
            color: #999999;
            font-size: 1.8rem;
          "
        >
          暂无数据
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/views/member/components/commonHeader";
import { getCourseClassifyList, getUserCourseList } from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "index",
  components: { CommonHeader },
  data() {
    return {
      navList: [],
      currentNavIndex: 0,
      list: [],
    };
  },
  computed: {
    ...mapState(["isMobile"]),
  },
  watch: {
    currentNavIndex(e) {
      this.getUserCourseList();
    },
  },
  mounted() {
    this.getCourseClassifyList();
  },
  methods: {
    toCourseDetailPage(id) {
      this.$router.push({
        path: "/courseDetail",
        query: {
          id,
        },
      });
    },
    async getCourseClassifyList() {
      let res = await getCourseClassifyList();
      this.navList = res.data;
      this.navList.unshift({
        id: "",
        name: "全部",
      });
      await this.getUserCourseList();
    },
    async getUserCourseList() {
      let res = await getUserCourseList({
        cate_id: this.navList[this.currentNavIndex].id,
      });
      this.list = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-cont {
  width: 100%;

  .nav-cont {
    height: 9rem;
    background-color: #fafafa;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .nav-item {
      padding: 0 3rem;
      border-bottom: 0.3rem solid transparent;
      color: #4a4948;
      line-height: 9rem;
      font-size: 1.8rem;
      transition: all 0.3s;
      cursor: pointer;

      &.active {
        color: var(--default-color);
        border-color: var(--default-color);
      }
    }
  }

  .course-cont {
    padding: 3rem 6rem;
    display: flex;
    flex-wrap: wrap;
    background-color: #ffffff;
  }
}

.course-item {
  margin: 1rem;
  background: #ffffff;
  box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.06);
  border-radius: 0 0 5px 5px;
  cursor: pointer;

  .image-cont {
    width: 100%;
    height: 22rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .bottom-cont {
    padding: 2rem 3rem;

    .title {
      font-size: 2rem;
      color: #000000;
    }

    .tag-list {
      display: flex;
      justify-content: flex-start;
      margin-top: 1.5rem;

      .tag-item {
        font-size: 1.6rem;
        color: var(--default-color);
        height: 3.5rem;
        border-radius: 2rem;
        line-height: 3.3rem;
        border: 0.1rem solid var(--default-color);
        padding: 0 1.5rem;
        margin-right: 1rem;
      }
    }

    .user-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;

      .avatar {
        width: 5rem;
        height: 5rem;
        background-color: #7c7c7c;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .name {
        font-size: 2rem;
        padding-left: 2rem;
      }
    }
  }
}
</style>
