<template>
  <div class="__section-main__">
    <common-banner :banner-list="bannerList"></common-banner>

    <div class="__container__">
      <div class="common-page-title wow bounceInLeft">
        <!--        <div class="title"><span>以策略为先导</span> 的网站建设服务商</div>-->
        <div class="title" style="margin-top: 4rem">近期项目播报</div>
      </div>
    </div>

    <div class="news-list">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="news-item wow fadeInUp"
      >
        <div class="left-cont">
          <div class="title" @click="toNewsDetailPage(item.id)">
            {{ item.name }}
          </div>
        </div>
        <div
          :style="{ backgroundImage: `url(${item.resource})` }"
          class="image-cont"
          @click="toNewsDetailPage(item.id)"
        ></div>
        <div class="time-cont">
          <div class="day">{{ item.update_time.substring(8, 10) }}</div>
          <div class="time">{{ item.update_time.substring(0, 7) }}</div>
        </div>
      </div>
    </div>
    <div class="jl-fex-c jl-fex-item-c" style="height: 20rem">
      <el-pagination
        :current-page.sync="listQuery.page"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import CommonBanner from "@/components/commonBanner";
import { getBannerList, getNewsList } from "@/request/api";

export default {
  components: { CommonBanner },
  data() {
    return {
      bannerList: [],
      list: [],
      listQuery: {
        page: 1,
        limit: 10,
      },
      total: null,
    };
  },
  mounted() {
    this.getBannerList();
    this.getNewsList();
  },
  methods: {
    async getBannerList() {
      let res = await getBannerList({ cate_id: 3 });
      this.bannerList = res.data;
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getNewsList();
    },
    async getNewsList() {
      let res = await getNewsList({
        page: this.listQuery.page,
        limit: this.listQuery.limit,
      });
      this.total = res.data.total;
      this.list = res.data.data;
    },
    toNewsDetailPage(id) {
      this.$router.push({
        path: "/newsDetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.common-page-title {
  padding: 7rem 0;
  line-height: 1;

  .title {
    font-size: 3rem;

    span {
      font-weight: 600;
    }
  }
}

.news-list {
  display: flex;
  flex-direction: column;

  .news-item {
    padding: 6rem 0;
    display: flex;
    background-color: #f8f8f8;
    transition: all 0.3s;

    .left-cont {
      flex: 1;
      padding: 6rem 14.5rem 6rem 14.5rem;

      .title {
        font-size: 2.4rem;
        line-height: 1.5;
        cursor: pointer;
      }
    }

    .image-cont {
      width: 64.2rem;
      height: 36rem;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
    }

    .time-cont {
      width: 42rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .day {
        font-size: 3.6rem;
        line-height: 1;
        font-weight: 600;
      }

      .time {
        margin-top: 3rem;
        font-size: 1.6rem;
        line-height: 1;
      }
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
      background: #ffffff;
    }

    &:hover {
      color: var(--default-color);
    }
  }
}

@media screen and (max-width: 768px) {
  .home-banner-cont {
    height: 50vh;
  }

  .classify-list {
    margin-top: 5rem;
    padding-left: 0;
  }

  .news-list {
    display: flex;
    flex-direction: column;

    .news-item {
      padding: 6rem 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #f8f8f8;
      transition: all 0.5s;

      .left-cont {
        flex: 1;
        padding: 6rem 5rem;

        .title {
          font-size: 3rem;
          line-height: 1.5;
          cursor: pointer;
        }
      }

      .image-cont {
        width: 64.2rem;
        height: 36rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
      }

      .time-cont {
        display: none;
      }

      &:nth-of-type(2n) {
        flex-direction: column;
        background: #ffffff;
      }

      &:hover {
        color: #005aab;
      }
    }
  }
}
</style>
