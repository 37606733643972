<template>
  <div>
    <common-header>用户密码修改</common-header>
    <div class="main-cont">
      <div class="form-item">
        <div class="label">手机号：</div>
        <div class="input-cont">
          <input
            placeholder="请输入您的手机号"
            :value="userInfo.mobile"
            type="number"
            disabled
          />
        </div>
      </div>
      <div class="form-item">
        <div class="label">获取验证码：</div>
        <div class="input-cont">
          <input type="text" placeholder="请输入验证码" v-model="code" />
          <div
            :class="{ disabled: disabled }"
            class="code-btn"
            @click="getVerificationCode()"
          >
            {{ disabled ? countDown + "秒后获取" : "获取验证码" }}
          </div>
        </div>
      </div>
      <div class="form-item">
        <div class="label">新密码：</div>
        <div class="input-cont">
          <input
            placeholder="请输入您的密码"
            type="password"
            v-model="password"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="label">确认密码：</div>
        <div class="input-cont">
          <input
            placeholder="请输入您的密码"
            type="password"
            v-model="re_password"
          />
        </div>
      </div>
      <div class="form-item">
        <div class="label"></div>
        <div class="submit-btn" @click="handleSubmit()">确认修改</div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHeader from "@/views/member/components/commonHeader";
import {
  checkVerificationCode,
  getVerificationCode,
  updatePassword,
} from "@/request/api";
import { mapState } from "vuex";

export default {
  name: "editPassword",
  components: { CommonHeader },
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      password: "",
      re_password: "",
      timer: null,
      countDown: 60,
      lock: true,
      disabled: false,
      code: "",
    };
  },
  methods: {
    async handleSubmit() {
      if (this.checkFormData()) {
        await checkVerificationCode({
          mobile: this.userInfo.mobile,
          type: 4,
          code: this.code,
        });
        let loading = this.$loading({ background: "rgba(0,0,0,0.5)" });
        await updatePassword({
          password: this.password,
          old_password: this.re_password,
        });
        loading.close();
        this.$message.success("修改成功");
        this.code = "";
        this.password = "";
        this.re_password = "";
      }
    },
    checkFormData() {
      if (!this.code) {
        this.$message.warning("请输入验证码");
        return false;
      } else if (!this.password) {
        this.$message.warning("请输入密码");
        return false;
      } else if (this.password !== this.re_password) {
        this.$message.warning("两次密码不一致");
        return false;
      }
      return true;
    },
    // 发送验证码
    async getVerificationCode() {
      let mobile = this.userInfo.mobile;
      let type = 4;

      if (!this.$tool.verifyPhone(mobile)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      }
      if (!this.lock) return;
      if (this.disabled) return;
      this.lock = false;
      setTimeout(() => {
        this.lock = true;
      }, 2000);
      let loading = this.$loading({ background: "rgba(0,0,0,0.5)" });
      await getVerificationCode({ mobile, type });
      loading.close();
      this.$message.success("发送成功");
      clearInterval(this.timer);
      this.disabled = true;
      this.timer = setInterval(() => {
        if (this.countDown <= 1) {
          clearInterval(this.timer);
          this.disabled = false;
          this.countDown = 5;
          return false;
        }
        this.countDown--;
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-cont {
  padding: 7.5rem 15.5rem 4rem;
  background-color: #ffffff;

  .form-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4rem;

    .label {
      color: #4a4948;
      font-size: 2rem;
      width: 18.5rem;
      text-align: left;
    }

    .input-cont {
      width: 40rem;
      height: 5rem;
      position: relative;

      input {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;
        border: 0.1rem solid #d4d9de;
        outline: none;
        padding: 0 2rem;
        font-size: 1.8rem;
      }

      .code-btn {
        width: 12.5rem;
        height: 5rem;
        line-height: 5rem;
        text-align: center;
        background-color: var(--default-color);
        color: #ffffff;
        font-size: 1.8rem;
        border-radius: 0 0.5rem 0.5rem 0;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;

        &.disabled {
          border-color: #999999;
          color: #999999;
          cursor: not-allowed;
        }
      }
    }

    .submit-btn {
      width: 40rem;
      height: 6rem;
      line-height: 6rem;
      background-color: var(--default-color);
      color: #ffffff;
      text-align: center;
      border-radius: 0.5rem;
      font-size: 2rem;
      cursor: pointer;
    }
  }
}
</style>
