<template>
  <div
    :style="{ width: `calc(${width} - 2rem)` }"
    class="product-item"
    @click="toCourseDetailPage(item.id)"
  >
    <div
      :style="{ backgroundImage: `url(${item.images})` }"
      class="image-cont"
    ></div>
    <div class="word-cont">
      <div class="title zlc-t-hide">{{ item.name }}</div>
      <div class="desc zlc-t-hide4">{{ item.introduction }}。</div>
      <div class="bottom-cont">
        <div>{{ item.count }}人学习</div>
        <div>{{ item.update_time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "productItem",
  props: {
    width: {
      type: String,
      default: "25%",
    },
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    toCourseDetailPage(id) {
      this.$router.push({
        path: "/productDetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.product-item {
  margin: 1rem;
  background: #ffffff;
  box-shadow: 0 0 4rem 0 rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  cursor: pointer;

  .image-cont {
    width: 100%;
    height: 22rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 0.5rem 0.5rem 0 0;
  }

  .word-cont {
    padding: 2rem 3rem;

    .title {
      font-size: 2rem;
      color: #000000;
    }

    .desc {
      font-size: 1.6rem;
      color: #333333;
      margin-top: 2rem;
      line-height: 1.5;
    }

    .bottom-cont {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333333;
      font-size: 1.6rem;
      margin-top: 3rem;
    }
  }
}
</style>
